<script>
// import BaseCuboidBox from "@/components/BaseCuboidBox";
import BaseCuboidBox from "@/components/BaseCuboidBox2";
import WeatherIcon from '@/components/WeatherIcon'
const dayList = [
  '周日',
  '周一',
  '周二',
  '周三',
  '周四',
  '周五',
  '周六',
]
export default {
  name: "EnvironmentMonitor",
  components: {
    BaseCuboidBox,
    WeatherIcon
  },
  data() {
    return {
      dayList,
      temperature: 0,
      humidity: 0,
      noise: 0,
      windspeed: 0,
      pm25: 0,
      pm10: 0,
      weatherListdata: [],
      envData: [],
      day1: 0,
      day2: 0,
      day3: 0,
      toDay: "",
      barometerStatistic: [],
      // {"week":"星期四","temperature":"22℃","weather":"多云","weatherIconIndex":2,"adverseStatus":0},{"week":"星期五","temperature":"20℃","weather":"多云","weatherIconIndex":2,"adverseStatus":0},{"week":"星期六","temperature":"16℃/13℃","weather":"小雨转多云","weatherIconIndex":8,"adverseStatus":0},{"week":"星期日","temperature":"21℃/14℃","weather":"多云","weatherIconIndex":2,"adverseStatus":0},{"week":"星期一","temperature":"23℃/16℃","weather":"多云","weatherIconIndex":2,"adverseStatus":0},{"week":"星期二","temperature":"25℃/17℃","weather":"晴","weatherIconIndex":1,"adverseStatus":0},{"week":"星期三","temperature":"26℃/18℃","weather":"晴","weatherIconIndex":1,"adverseStatus":0},{"week":"星期四","temperature":"27℃/18℃","weather":"晴","weatherIconIndex":1,"adverseStatus":0},{"week":"星期五","temperature":"27℃/20℃","weather":"多云转晴","weatherIconIndex":2,"adverseStatus":0}
    };
  },
  mounted() {
    this.deviceWeatherData();
    this.deviceEnviCurData();
    this.temperature = 24;
    this.humidity = 35;
    this.noise = 67;
    this.windspeed = 40;
    this.pm25 = 76;
    this.pm10 = 45;
    this.getDay();
    this.getEnvironmentBarometerStatistic()
  },
  created () {
    this.toDay = this.getToday();
  },
  methods: {
    clickTo(){
      this.$router.push("/mdms/dpEnvironment?date="+(Date.parse(new Date())/1000));
    },
    getToday() {
      let date = new Date();
      let day = date.getDay();
      //   console.log('day', day);
      switch (day) {
        case 0:
          return "星期日";
        case 1:
          return "星期一";
        case 2:
          return "星期二";
        case 3:
          return "星期三";
        case 4:
          return "星期四";
        case 5:
          return "星期五";
        case 6:
          return "星期六";
        default:
          break;
      }
    },
     //晴雨表
    getEnvironmentBarometerStatistic() {
      this.$api.getFutureThreedays().then((res) => {
        console.log(res)
        if (res.code == 200) {
          this.barometerStatistic = (res.data.length && res.data) || [];
        }
        // console.log(' this.barometerStatistic', this.barometerStatistic);
      });
    },
    getDay() {
      let date = new Date();
      this.day1 = (date.getDay() + 1) % 7;
      this.day2 = (date.getDay() + 2) % 7;
      this.day3 = (date.getDay() + 3) % 7;
    },
    deviceWeatherData() {

      //   this.$api.deviceWeatherData().then((res) => {
      this.$api.queryDeviceWeatherDataFuture().then((res) => {
        if (res.code == "200") {
          //   console.log(res.data);

          this.weatherListdata = res.data;
          this.weatherListdata.length && this.weatherListdata.forEach(v => {
            v.weatherIndex = (Number(v.weather_iconid) + 1).toString()
          })
        }
      });
    },
    // 温湿度
    deviceEnviCurData() {
      this.$api.getDashboardStatistics().then((res) => {
        if (res.code == "200") {
          console.log('1111', res.data)
          if (res.data) {
            this.envData = [
              {
                data: res.data && res.data.pmtwo,
                unit: res.data && res.data.pmtwoUnit,
                percent: res.data && res.data.pmtwo ? (Number(res.data.pmtwo) / 300) * 100 : 50,
                title: 'PM2.5'
              },
              {
                data: res.data && res.data.pmten,
                unit: res.data && res.data.pmtenUnit,
                percent: res.data && res.data.pmten ? (Number(res.data.pmten) / 300) * 100 : 50,
                title: 'PM10'
              },
              {
                data: res.data && res.data.noise,
                unit: res.data && res.data.noiseUnit,
                percent: res.data && res.data.noise ? (Number(res.data.noise) / 120) * 100 : 50,
                title: '噪音'
              },
              {
                data: res.data && res.data.speed,
                unit: res.data && res.data.speedUnit,
                percent: res.data && res.data.speed ? (Number(res.data.speed) / 75) * 100 : 50,
                title: '风速'
              },
            ];
            // console.log(this.envData);
            // this.env_chart(data, unit);
          }
          //   else {
          // let data = [0, 0, 0, 0, 0, 0];

          //   }
        }
      });
    },
  },
};
</script>

<template>
  <div class="flex-center-container">
    <div class="container-left">
      <div class="weather-container">
        <div class="weather-column">
          <div
            class="weather-item"
            v-for="i in 3"
            :key="i"
            :class="{ special: barometerStatistic[i - 1].adverseStatus }"
          >
            <div class="top">
              <label class="week">{{
                barometerStatistic[i - 1].week == toDay
                  ? "今天"
                  : barometerStatistic[i - 1].week
              }}</label>
              <WeatherIcon
                :icon="barometerStatistic[i - 1].weatherIconIndex"
                :size="20"
              />
            </div>
            <div class="bottom">
              <label class="temperature">{{
                barometerStatistic[i - 1].temperature
              }}</label>
              <label class="note">{{
                barometerStatistic[i - 1].weather
              }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="xiantiao"></div>
    <div class="container-right">
      <div class="right-item" v-for="(item, index) in envData" @click="clickTo(item)" :key="index">
        <label class="data">
          {{index!=3?parseInt(item.data):item.data}}
          {{item.unit}}
        </label>
        <div class="percent">
          <!-- <BaseCuboidBox /> -->
          <BaseCuboidBox
            class="in"
            :percent="item.percent?item.percent:10"
            :index="index"
            :topColor="'#90caff'"
            :leftColor="['#3e86d0', '#60b1fc']"
            :rightColor="['#2277ca', '#50a6f8']"
          />
        </div>
        <label class="title">{{item.title}}</label>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.weather-container {
  width: 100%;
  height: 100%;
  display: flex;
  .weather-column {
    .weather-item {
      width: 60px;
      flex: 1;
      flex-direction: column;
      position: relative;
      // &.special {
      //   .top .week {
      //     font-size: 12px;
      //     font-weight: bold;
      //     color: transparent;
      //     background: linear-gradient(
      //       180deg,
      //       #ffffff,
      //       #ffffff 22%,
      //       #ff7777 100%
      //     );
      //     text-shadow: 0px 2px 4px rgba(255, 178, 178, 0.58);
      //     background-clip: text;
      //   }
      //   .bottom {
      //     background: url("../../assets/img/label_base_7.png");
      //     background-size: 100% 100%;
      //   }
      // }
      // &:not(:last-child) {
      //   margin-right: 7px;
      //   &:after {
      //     position: absolute;
      //     right: -4px;
      //     top: 4px;
      //     content: "";
      //     display: inline-block;
      //     width: 1px;
      //     height: 36px;
      //     opacity: 0.6;
      //     background: linear-gradient(
      //       0deg,
      //       rgba(255, 255, 255, 0),
      //       rgba(252, 253, 253, 0.35) 18%,
      //       #f7f8f8 50%,
      //       rgba(248, 249, 249, 0.85) 58%,
      //       rgba(251, 252, 252, 0.49) 77%,
      //       rgba(255, 255, 255, 0)
      //     );
      //   }
      // }
      .top {
        width: 100px;
        height: 20px;
        padding: 0 12px 0 16px;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        .week {
          font-size: 12px;
          color: #b8e9fc;
        }
      }
      .bottom {
        width: 100px;
        height: 32px;
        padding: 0 12px 7px 12px;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        .temperature,
        .note {
          white-space: nowrap;
          font-size: 12px;
          transform: scale(0.833);
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
.xiantiao{
  // position: absolute;
  // right: -4px;
  // top: 4px;
  // content: "";
  display: inline-block;
  width: 1px;
  height: 80%;
  opacity: 0.6;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0),
    rgba(252, 253, 253, 0.35) 18%,
    #f7f8f8 50%,
    rgba(248, 249, 249, 0.85) 58%,
    rgba(251, 252, 252, 0.49) 77%,
    rgba(255, 255, 255, 0)
  );
}
.container-left {
  // flex: 2;
  width: 140px;
  height: 100%;
  //   display: inline-flex;
  flex-direction: column;
  //   align-items: center;
  justify-content: space-between;
  padding: 4px 16px 4px 8px;
  .title {
    color: #0095ff;
    font-weight: bold;
  }
  .todayItem {
    width: 175px;
    text-align: center;
    padding: 0 0 7px 0;
    border-bottom: 1px solid #3780bf;
    color: #bfd8ee;
    img {
      width: 43px;
    }
  }
  .futureItem {
    width: 175px;
    text-align: center;
    color: #bfd8ee;
    img {
      width: 30px;
    }
  }
  .left-item {
    display: inline-flex;
    align-items: center;
    .serialno {
      font-family: acens;
      font-size: 18px;
    }
    .ali-icon {
      font-size: 28px;
      margin: 0 16px;
    }
    .detail {
      display: inline-flex;
      flex-direction: column;
      font-size: 12px;
      line-height: 1;
      .desc {
        margin-bottom: 8px;
      }
    }
  }
}
.container-right {
  height: 100%;
  flex: 3;
  display: flex;
  padding: 8px 0;
  .right-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 2px;
    align-items: center;
    font-size: 12px;
    .data {
      //   font-weight:
    }
    .percent {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      margin: 8px 0;
      position: relative;
      width: 16px;
      .in {
        position: absolute;
        bottom: 0;
        transition: height 1.5s;
      }
      
    }
    .title{
      font-size: 12px;
    }
  }
}
</style>
