var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-center-container"},[_c('div',{staticClass:"extra-info",on:{"click":function($event){return _vm.clickTo()}}},[_c('div',{staticClass:"name"},[_vm._v("项目总人数")]),_c('div',{staticClass:"count"},[_vm._v(_vm._s(_vm.eqi_totalNum))]),_c('div',{staticClass:"name"},[_vm._v("人")])]),_c('div',{staticStyle:{"width":"100%","height":"80%","margin-top":"20%"}},[_c('ChartBar',{attrs:{"direction":'horizontal',"legendColor":['#0FB77B'],"nameWidth":60,"nameData":['进场人员数', '在场人员数', '离场人员数'],"barColor":[
        {
          topColor: 'transparent',
          leftColor: ['#0c80a1', '#0fb57c'],
          rightColor: ['#0d7ea3', '#12805d'],
        },
      ],"barData":_vm.barData},on:{"btnClick":_vm.btnClick}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }