<script>
import CenterViewSite from "./CenterViewSite";
import CenterViewProject from "./CenterViewProject";
import CenterViewNews from "./CenterViewNews";
import CenterViewNotice from "./CenterViewNotice";
import vedioMonitor from "./vedioMonitor2";

export default {
  name: "CenterView",
  components: {
    CenterViewSite,
    CenterViewProject,
    CenterViewNews,
    CenterViewNotice,
    vedioMonitor,
  },
  data() {
    return {
      viewTab: "CenterViewSite",
      swiperData: [],
      hasNumber: [],
      count: 0,
      residueNumber: [],
      SettingData: [],
      tabIndex: 0,
      renderSettingData: [],
      days: 0,
      timer:null,
    };
  },
  created() {
    this.getMilestoneConfig();
    this.getSettingData();
    this.getProject();
  },
  mounted() {
    this.timer = window.setInterval(this.tabChange, 20000)
  },
  destroyed() {
    this.timer && window.clearInterval(this.tabChange)
  },
  computed: {
    picServer() {
      return this.$store.state.picServer;
    },
  },
  methods: {
    tabChange() {
      // this.viewTab = this.viewTab == '1' ? '2' : '1'
        
        if(this.viewTab=='CenterViewSite'){
          this.viewTab='CenterViewProject'
        }else if(this.viewTab=='CenterViewProject'){
          this.viewTab='CenterViewNews'
        }else if(this.viewTab=='CenterViewNews'){
          this.viewTab='CenterViewNotice'
        }else if(this.viewTab=='CenterViewNotice'){
          this.viewTab='CenterViewSite'
        }
    },
    check(d) {
      let days;

      let start = new Date(d.replace(/-/g, "/"));
      let end = new Date();
      if (end.getTime() >= start.getTime()) {
        days = (end.getTime() - start.getTime()) / 1000 / 3600 / 24;
      } else {
        days = (start.getTime() - end.getTime()) / 1000 / 3600 / 24;
      }

      return (Math.ceil(days - 1));//Math.ceil(days-1)就是相差天数
    },
    getProject() {
      this.$api.queryProject().then(res => {
        // console.log(res);
        if (res.code == 200) {
          let days = this.check(res.data.startDate);
          this.days = (days.toString()).split('')
          //   console.log('days', this.days);
        }
      })
    },
    getMilestoneConfig() {
      this.$api.getMilestoneConfig().then((res) => {
        if (res.code == "200") {
          if (res.data.list && res.data.list.length > 0) {
            res.data.list.forEach((item) => {
              item.swiperImg = item.swiperImg
                ? this.picServer + item.swiperImg
                : require("./img/noPic.png");
            });
            this.swiperData = res.data.list;
            // console.log(this.$store.state.picServer)
            // console.log(this.swiperData)
          }

          //   this.hasNumber = res.data.hasNumber.toString().split("");//暂时注释掉 以后有用

          this.count = res.data.count;
          let residueNumber = res.data && res.data.residueNumber ? res.data.residueNumber.toString() : '0'
          this.residueNumber = residueNumber.split("");
        }
      });
    },
    // 看板
    getSettingData() {
      this.$api
        .getSettingData()
        .then((res) => {
          if (res.code == "200") {
            // console.log("看板调用方法成功，返回看板方法返回的数据", res);
            if (res.data && res.data.length > 0) {
              // let arr = [];
              // res.data.forEach((item) => {
              //   item.fileUrl = item.fileUrl ? JSON.parse(item.fileUrl) : "";
              //   this.SettingData = res.data;
              //   let data = this.SettingData[1 - 1];
              //   this.renderSettingData = data;
              // });
              this.renderSettingData = res.data

              console.log(this.renderSettingData)
            }
          }
        })
      // .then(() => {
      //   this.timer = setInterval(() => {
      //     this.siwperSettingData();
      //   }, 10000);
      // });
    },
    // siwperSettingData() {
    //   this.tabIndex++;
    //   if (this.tabIndex == 4) this.tabIndex = 0;
    //   if (this.tabIndex == 0) return;
    //   console.log("测试数据", this.SettingData);
    //   if (typeof this.SettingData !== undefined && this.SettingData) {
    //     let data = this.SettingData[this.tabIndex - 1];
    //     this.renderSettingData = data;
    //     console.log(this.renderSettingData)
    //   }
    // },
    btnclick(value) {
      this.tabIndex = value == 'CenterViewSite' ? 0 : value == 'CenterViewProject' ? 1 : value == 'CenterViewNews' ? 2 : value == 'CenterViewNotice' ? 3 : 0
    },
  },
};
</script>

<template>
  <div class="centerView">
    <div class="full-container padding-8">
      <div class="container-top">
        <a-tabs class="bigscreen-tabs" v-model="viewTab" @change="btnclick">
          <a-tab-pane key="CenterViewSite" tab="工地"></a-tab-pane>
          <a-tab-pane key="CenterViewProject" tab="项目预览"></a-tab-pane>
          <a-tab-pane key="CenterViewNews" tab="宣传快讯"></a-tab-pane>
          <a-tab-pane key="CenterViewNotice" tab="通知公告"></a-tab-pane>
          <a-tab-pane key="vedioMonitor" tab="视频监控"></a-tab-pane>
        </a-tabs>
        <!-- <div class="top-right">
          <div class="count yellow">
            <label class="title">安全施工天数</label>
            <label class="number" v-for="(item, ind) in days" :key="ind">{{item}}</label>
            <label class="unit">天</label>
          </div>
        </div> -->
      </div>
      <div class="container-bottom">
        <component :is="viewTab" :swiperData="swiperData" :renderSettingData="renderSettingData" />
      </div>


    </div>
  </div>
</template>

<style lang="scss" scoped>

.centerView {
  .full-container {
    // background-color: #0665bd;
    border-radius: 9px;
    z-index: 999;
    opacity: 1;
  }

  .padding-8 {
    padding: 4px 8px 8px 8px;
    overflow: hidden;
    .container-top {
      height: 24px;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      margin-bottom: 8px;
      .title{
        margin-right: 80px;
      }
      .top-right {
        .count {
          display: inline-flex;
          align-items: center;
          &:not(:last-child) {
            margin-right: 10px;
          }
          &.blue {
            color: #03c1fe;
            .number {
              background: rgba(1, 148, 252, 0.05);
              border: 1px solid rgba(0, 183, 239, 0.3);
            }
          }
          &.yellow {
            color: #ffbb00;
            .number {
              background: rgba(255, 131, 60, 0.1);
              border: 1px solid rgba(255, 187, 0, 0.38);
            }
          }
        }
        .title {
          font-size: 14px;
          color: #ffffff;
          margin-right: 6px;
          line-height: 24px;
        }
        .number {
          width: 24px;
          //   padding: 0 5px;
          height: 24px;
          line-height: 24px;
          font-size: 18px;
          font-family: acens;
          text-align: center;
          line-height: 26px;
          margin-right: 4px;
          border-radius: 2px;
        }
        .unit {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
    .container-bottom {
      //   height: calc(100% - 50px);
      height: 96%;
    }
  }
  // .centerView .padding-8 .container-bottom{
  //     height: calc(100% - 100px) !important;
  // }
}
</style>
