<script>
import BaseCuboidBox from '@/components/BaseCuboidBox3'
export default {
  name: 'ChartBar',
  components: {
    BaseCuboidBox
  },
  props: {
    // 方向，vertical-垂直、horizontal-水平
    direction: {
      type: String,
      default: 'horizontal'
    },
    legendColor: {
      type: Array,
      default: () => ['#07CAEC', '#C98C56', '#FF4241']
    },
    nameWidth: {
      type: Number,
      default: 64
    },
    nameData: {
      type: Array,
      default: () => []
    },
    barColor: {
      type: Array,
      default: () => []
    },
    barData: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    legendData() {
      return this.barData.map((ele, index) => ({
        name: ele.seriesName,
        color: this.legendColor[index]
      }))
    },
    seriesData() {
      let totalArr = []
      let maxData = 0
      this.nameData.forEach(() => {
        totalArr.push(0)
      })
      this.barData.forEach((barEle) => {
        barEle.data.forEach((ele, index) => {
          totalArr[index] += ele
        })
      })
      totalArr.forEach((ele) => {
        if (ele > maxData) {
          maxData = ele
        }
      })
      let datas = []
      for (let i = 0; i < this.nameData.length; i++) {
        let arr = this.barData.map((item) => { return item.data[i] })
        let number = 0
        for (let j = 0; j < arr.length; j++) {
          number += arr[j]
        }
        datas.push({
          name: this.nameData[i],
          number: number || 0,
          series: this.barData.map((barEle) => ({
            name: barEle.seriesName,
            data: barEle.data[i],
            percent: Math.round((barEle.data[i] / maxData) * 10000) / 100
          }))
        })
      }

      return datas
      // this.nameData.map((ele, index) => ({
      // 	name: ele,
      // 	number:this.barData.map((item)=>{return item.data[index]}),
      // 	series: this.barData.map((barEle) => ({
      // 		name: barEle.seriesName,
      // 		data: barEle.data[index],
      // 		percent: Math.round((barEle.data[index] / maxData) * 10000) / 100
      // 	}))
      // }))
    }
  },
}
</script>

<template>
  <div class="chart-container">
    <ul class="legend">
      <li class="legend-item" v-for="(ele, index) in legendData" :key="index">
        <label class="symbol" :style="`background: ${ele.color};`"></label>
        <label class="name">{{ ele.name }}</label>
      </li>
    </ul>
    <div class="chart-list">
      <div class="chart-item" v-for="(ele, index) in seriesData" :key="index">
        <div class="name" :style="`width: ${nameWidth}px;`">{{ ele.name }}</div>
        <a-popover placement="topLeft" :content="ele.name">
          <div class="instance" :style="`margin-right: -${(ele.series.length - 1) * 10}px;`">
            <BaseCuboidBox
              v-for="(seriesEle, seriesIndex) in ele.series"
              :key="seriesIndex"
              class="instance-item"
              :direction="direction"
              :percent="seriesEle.percent"
              :topColor="barColor[seriesIndex].topColor"
              :leftColor="barColor[seriesIndex].leftColor"
              :rightColor="barColor[seriesIndex].rightColor"
            />
            <div style="margin:0 10px;min-width:30px;font-size: 12px;">{{ele.number}}</div>
          </div>
        </a-popover>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chart-container {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 8px;
  .legend {
    width: 100%;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin:0;
    .legend-item {
      display: inline-flex;
      align-items: center;
      &:not(:last-child) {
        margin-right: 8px;
      }
      .symbol {
        width: 16px;
        height: 8px;
        display: inline-flex;
        background: #138eff;
        // border: 1px solid #ffffff;
        margin-right: 8px;
      }
      .name {
        font-size: 12px;
        letter-spacing: 1px;
      }
    }
  }
  .chart-list {
    width: 100%;
    height: calc(100% - 24px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .chart-item {
    width: 100%;
    height: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .name {
      display: inline-flex;
      margin-right: 16px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.75);
    }
    .instance {
      flex: 1;
      width: 0;
      height: 16px;
      display: flex;
      .instance-item {
        &:not(:first-child) {
          margin-left: -10px;
        }
      }
    }
  }
}
</style>
