<script>
	export default {
		name: 'CenterViewNotice',
		props: {
			renderSettingData: {
				type: Array,
				default: () => [],
			},
		},
		data () {
			return {
				datas:{},
				url:''	
			}
		},
		mounted(){
			console.log(this.renderSettingData)
			for(let i=0;i<this.renderSettingData.length;i++){
				if(this.renderSettingData[i].publicizePosition=='3'){
					this.datas= this.renderSettingData[i]
					if(this.renderSettingData[i].fileUrl){
						let imgList = JSON.parse(this.renderSettingData[i].fileUrl)
						if(imgList.length>0){
							console.log(imgList[0])
							this.url =imgList[0].url
							console.log(imgList[0].url)
							let types = this.checkFileType(imgList[0].url)
							console.log(types)
						}
					}
					
				}
			}
		},
		methods:{
			checkFileType(suffix) {
            var type="";
            switch (suffix) {
                case 'mp4': 
                    type = 'mp4'; 
                    break;
                case 'ppt': 
                case 'pptx':
                case 'doc':
                case 'docx':
                case 'pdf':
                    type = 'office';
                    break;
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'bmp':
                case 'gif':
                    type = 'pic';
                    break;
            }
            return type;
        }
		}
	}
</script>

<template>
	<div class="full-container">
		<div class="site-container">
			<div class="site-background">
				<p class="content" v-if="datas.contentText">
					{{datas.contentText}}
				</p>
				<img v-if="(url.includes('.jpg')||url.includes('.png'))" style="width:100%;border-radius: 9px;height:100%;" :src="url" alt="">
				<video v-if="url.includes('.mp4')||url.includes('.ogg')"  :src="url"  autoplay="autoplay" style="width:100%;height:100%;" ></video>
			</div>
		</div>
		<!-- <p class="content">
			望各位管理人员和各班组工人同志引以为戒!
		</p>
		<p class="content">
			特此通告。
		</p> -->
	</div>
</template>

<style lang="scss" scoped>
	.full-container {
		overflow-y: auto;
	}
	.content {
		text-indent: 28px;
		text-align: justify;
		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}
	.site-container {
  // height: calc(100% - 118px);
  //   height: calc(100% - 218px);
  height: 100%;
  margin-bottom: 8px;
  padding: 2px;
  border-radius: 9px;
  background: linear-gradient(
    180deg,
    #427ec7,
    rgb(0, 98, 184) 35%,
    rgb(0, 98, 184) 65%,
    #427ec7
  );
  .site-background {
    // background-color: #0665bd;
    padding: 5px;

	background: linear-gradient(90deg, #0D589D 0%, #0E4477 100%);
	box-shadow: inset 0px 0px 10px 0px rgba(255, 255, 255, 0.5);

    border-radius: 9px;
    width: 100%;
    height: 100%;
    .image-container {
      width: 100%;
      height: 100%;
      border-radius: 9px;
      cursor: pointer;
    }
  }

  .bimButton {
    // position: absolute;
    // z-index: 3;
    float: right;
    background-color: rgba($color: #1885be, $alpha: 0.8) !important;
    color: #ffffff;
    // right: 0;
    // bottom: calc(50% - 50px);
    border: none;
    margin-top: -35px;
    margin-right: 5px;
  }
}
</style>
