<script>
// import ChartBar from "@/components/ChartBarRoll";
// import ChartBar from "@/components/ChartBar";

import ChartBar from "@/components/ChartBarList2";


export default {
  name: "KeyDeviceMonitor",
  components: {
    ChartBar,
  },
  data() {
    return {
      eqi_totalNum: 0,
      barData: [],
    };
  },
  created() {
    this.getGroupStatistical();
  },
  methods: {
    // 重点设备监控
    getGroupStatistical() {
      this.$api.getPresenceStatistics().then((res) => {
        if (res.code == "200") {
          let arrTotal = [];
          let onlineNumber = {
            seriesName: "",
            data: [],
          };
          onlineNumber.data[0]=res.data.inCount
          onlineNumber.data[1]=res.data.bePresentCount
          onlineNumber.data[2]=res.data.outCount
          arrTotal.push(onlineNumber);
          this.barData = arrTotal;
          this.eqi_totalNum = res.data.totalCount;
          console.log(res)
        }
      });
    },
    btnClick(data) {
      console.log(data);
      let num =''
      if(data.name=='离场人员数'){
        num=2
      }else if(data.name=='在场人员数'){
        num=1
      }else{
        num=1
        return false
      }
      this.$router.push("/pcms/labour/attendance/dpList?inOutType="+num);
    },
    clickTo(){
        this.$router.push("/pcms/labour/person/dpList");
    },
  },
};
</script>
<template>
  <div class="flex-center-container">
    <div class="extra-info" @click="clickTo()">
        <div class="name">项目总人数</div>
        <div class="count">{{eqi_totalNum}}</div>
        <div class="name">人</div>
    </div> 
    <div style="width: 100%;height:80%;margin-top:20%;">
      <ChartBar
        @btnClick="btnClick"
        :direction="'horizontal'"
        :legendColor="['#0FB77B']"
        :nameWidth="60"
        :nameData="['进场人员数', '在场人员数', '离场人员数']"
        :barColor="[
          {
            topColor: 'transparent',
            leftColor: ['#0c80a1', '#0fb57c'],
            rightColor: ['#0d7ea3', '#12805d'],
          },
        ]"
        :barData="barData"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
// background: linear-gradient(180deg, #FFFFFF 0%, #1EB3ED 19%, #1AA0E7 39%, #339FFF 56%, #285F8A 100%);
.flex-center-container {
  position: relative;
  .extra-info {
    display: flex;cursor: pointer;
    height: 30%;
    margin: 0 auto;
    position: absolute;
    left: 20px;top:20px;
    .name {
      font-size: 12px;
      color: #B8E9FC;
    }
    .count {
      font-size: 20px;
      font-family: acens;
      color: #FFFFFF;
      line-height: 24px;
      text-shadow: 0px 2px 4px rgba(178, 232, 255, 0.58);
      margin: 0 10px;
    }
    .name {
      font-size: 14px;
    }
  }
}
</style>
