<script>
	export default {
		name: 'TitleDecoration',
		props: {
			width: {
				type: [Number, String],
				default: '100%'
			},
			title: {
				type: String,
				default: ''
			}
		}
	}
</script>

<template>
	<div class="title-decoration" :style="`width: ${width};`">
		<div class="decoration-container"></div>
		<div class="title-container">
			<label class="title-name">
				{{ title }}
			</label>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	.title-decoration {
		height: 36px;
		padding: 7px 0;
		background: linear-gradient(
			270deg,
			rgba(0, 222, 255, 0),
			rgba(39, 159, 254, 0.2) 50%,
			rgba(0, 222, 255, 0)
		);
		position: relative;
		.decoration-container {
			position: absolute;
			top: 0;
			left: 0;
			width: 36px;
			height: 36px;
			background: url('../assets/img/title_icon.png');
		}
		.title-container {
			height: 22px;
			background: linear-gradient(
				270deg,
				rgba(0, 222, 255, 0),
				rgba(39, 159, 254, 0.65) 50%,
				rgba(0, 222, 255, 0)
			);
			.title-name {
				font-family: biaotihei;
				font-size: 18px;
				line-height: 22px;
				background: linear-gradient(180deg, #ffffff, #ffffff 22%, #77d8ff 100%);
				background-clip: text;
				color: transparent;
				text-shadow: 0px 2px 4px 0px #004157;
				padding: 0 16px 0 44px;
			}
		}
	}
</style>
