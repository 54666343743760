var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-center-container"},[_c('div',{staticStyle:{"width":"20%","margin-right":"2%","height":"100%"}},[_c('div',{staticClass:"extra-info",on:{"click":function($event){return _vm.clickTo('')}}},[_c('div',{staticClass:"name"},[_vm._v("设备总数")]),_c('div',{staticClass:"count"},[_vm._v(_vm._s(_vm.navData.totalNumber || 0))])]),_c('div',{staticClass:"extra-info",on:{"click":function($event){return _vm.clickTo(1)}}},[_c('div',{staticClass:"name"},[_vm._v("在线总数")]),_c('div',{staticClass:"count"},[_vm._v(_vm._s(_vm.navData.onlineTotalNumber || 0))])]),_c('div',{staticClass:"extra-info",on:{"click":function($event){return _vm.clickTo(0)}}},[_c('div',{staticClass:"name"},[_vm._v("离线总数")]),_c('div',{staticClass:"count"},[_vm._v(_vm._s(_vm.navData.offlineTotalNumber || 0))])])]),_c('div',{staticStyle:{"width":"78%","height":"100%"}},[_c('ChartBar',{attrs:{"direction":'horizontal',"legendColor":['#07CAEC', '#C98C56', '#FF4241'],"nameWidth":60,"nameData":['视频监控', '环境监测', '用电监测', '人行道闸', '广播音柱'],"barColor":[
        {
          topColor: '#fff',
          leftColor: ['#285F8A', '#06D3F0'],
          rightColor: ['#285F8A', '#06D3F0'],
        },
        {
          topColor: '#C98C56',
          leftColor: ['#FFE8C3', '#C98C56'],
          rightColor: ['#FFE8C3', '#C98C56'],
        },
        {
          topColor: '#FF4241',
          leftColor: ['#FF6766', '#FF4241'],
          rightColor: ['#FF6766', '#FF4241'],
        },
      ],"barData":_vm.barData},on:{"btnClick":_vm.btnClick}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }