<script>
export default {
  name: "BaseCuboidBox",
  props: {
    // 方向，vertical-垂直、horizontal-水平
    direction: {
      type: String,
      default: "vertical",
    },
    // 占比
    percent: {
      type: Number,
      default: 100,
    },
    // 顶部颜色
    topColor: {
      type: String,
      default: "#c5c5c6",
    },
    // 左侧颜色
    leftColor: {
      type: Array,
      default: () => ["rgba(171, 171, 171, 0.08)", "#c1c2c3"],
    },
    // 右侧颜色
    rightColor: {
      type: Array,
      default: () => ["rgba(171, 171, 171, 0.08)", "#dbdbdb"],
    },
    index:{
        type: Number,
        default: 0,
    }
  },
};
</script>

<template>
  <div :class="`cuboid-box ${direction}`" style="width:100%;height:100%;display:flex;justify-content: center;">
    <div
      style="cursor: pointer;
        width: 8px;
        background: linear-gradient(180deg, #FFE8C3 0%, #C98C56 19%, #C59652 100%);
        box-shadow: 0px -3px 10px 0px #d8a765;align-self:flex-end;
      "
      :style="index>=2?`width: 8px; height: ${percent}%;`:`width: 8px; height: ${percent}%;background: linear-gradient(180deg, #FFFFFF 0%, #1EB3ED 19%, #1AA0E7 39%, #339FFF 56%, #285F8A 100%);box-shadow: 0px -3px 10px 0px #279FFE;`"
    ></div>
  </div>
</template>

<style lang="scss" scoped>
.cuboid-box {
  position: relative;
  transition: width height 1.5s;
  &.vertical {
    .top-box-before {
      width: 16px;
      height: 0;
      border-bottom: 5px solid #c5c5c6;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }
    .top-box-after {
      width: 16px;
      height: 0;
      border-top: 5px solid #c5c5c6;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }
    .left-box {
      width: 8px;
      position: absolute;
      top: 10px;
      bottom: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(171, 171, 171, 0.08) 5%, #c1c2c3);
      transform: skew(0, 30deg);
      transform-origin: right top;
    }
    .right-box {
      width: 8px;
      position: absolute;
      top: 10px;
      bottom: 0;
      right: 0;
      background: linear-gradient(0deg, rgba(171, 171, 171, 0.08) 5%, #dbdbdb);
      transform: skew(0, -30deg);
      transform-origin: left top;
    }
  }
  &.horizontal {
    text-align: right;
    .top-box-before {
      display: inline-block;
      width: 0;
      height: 16px;
      border-right: 5px solid #c5c5c6;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
    }
    .top-box-after {
      display: inline-block;
      width: 0;
      height: 16px;
      border-left: 5px solid #c5c5c6;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
    }
    .left-box {
      height: 8px;
      position: absolute;
      right: 10px;
      left: 0;
      top: 0;
      background: linear-gradient(90deg, rgba(171, 171, 171, 0.08) 2%, #c1c2c3);
      transform: skew(-30deg, 0);
      transform-origin: right bottom;
    }
    .right-box {
      height: 8px;
      position: absolute;
      right: 10px;
      left: 0;
      bottom: 0;
      background: linear-gradient(90deg, rgba(171, 171, 171, 0.08) 2%, #dbdbdb);
      transform: skew(30deg, 0);
      transform-origin: right top;
    }
  }
}
</style>
