<script>
import ChartLine from "./ChartLine";
export default {
  name: "EnergyConsumMonitor",
  components: {
    ChartLine,
  },
  data() {
    return {
      times: [],
      lists: [],
      ytitle: '',
      viewTab: '1',
      listRec: [],
      listRec1: []
      // electricitys:[],
      // waters:[] 
    }
  },
  created() {
    this.getElectricityStatistical();
  },
  methods: {
    chartClick(val){
      var date = new Date();
      let year = date.getFullYear(); //获取完整的年份(4位)
      let time = year+'-'+ val.name
      var dates = new Date(time);
      this.$router.push("/pcms/labour/attendance/dpList?date="+dates.getTime()/1000);
    },
    // 能耗监测
    getElectricityStatistical() {
      this.$api.getIntoTrends().then((res) => {
        if (res.code == 200) {
          if (res.data && res.data.length > 0) {
            let times = []
            let electricitys = [];
            let result = res.data|| [];
            for (let i = 0; i < result.length; i++) {
              times.push(result[i].dateTime)
              electricitys.push(result[i].inCount)
            }
            this.times = times
            let lists = [
              { name: '人', data: electricitys },
            ]
            this.lists = lists;
            this.listRec = lists;
          }
        }
      });
    },
  },
};
</script>

<template>
  <div class="flex-center-container">
    <div class="chartItem">
      <ChartLine @chartClick="chartClick" :ytitle="ytitle" :color="['#C69154', '#000000']" :xdata="times" :ydata="lists"></ChartLine>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.flex-center-container {
  width: 100%;
  height: 100%;
  .tabItem {
    position: absolute;
    top: 25px;
    left: 25px;
    z-index: 10000;
    background: transparent;
  }
  .chartItem {
    width: 100%;
    height: calc(100% - 25px);
  }
}
</style>
