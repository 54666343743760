<script>
import ChartLine from "./ChartLine";
export default {
  name: "EnergyConsumMonitor",
  components: {
    ChartLine,
  },
  data() {
    return {
      times: [],
      lists: [],
      ytitle: '',
      viewTab: '1',
      listRec: [],
      listRec1: [],
      num:'',
      // electricitys:[],
      // waters:[] 
    }
  },
  created() {
    this.getElectricityStatistical();
  },
  methods: {
    chartClick(val){
      console.log(val.name);
      var date = new Date();
      let year = date.getFullYear(); //获取完整的年份(4位)
      let time = year+'-'+ val.name
      var dates = new Date(time);
      this.$router.push("/mdms/dpwater?date="+dates.getTime()/1000);
    },
    clickTo(){
      this.$router.push("/mdms/dpwater?dateNum=1");
    },
    // 能耗监测
    getElectricityStatistical() {
      this.$api.getWaterStatisticals().then((res) => {
        if (res.code == 200) {
            console.log(res)
          if (res.data && res.data.length > 0) {
            let times = []
            let num=''
            let electricitys = [];
            let result = res.data || [];
            for (let i = 0; i < result.length; i++) {
              times.push(result[i].dateTime)
              electricitys.push(result[i].count)
              if(i==29){
                num=result[i].count
              }
            }
            this.num=num
            this.times = times
            let lists = [
              { name: 't', data: electricitys },
            ]
            this.lists = lists;
            this.listRec = lists;
          }
        }
      });
    },
  },
};
</script>

<template>
  <div class="flex-center-container">
    <div class="chartItem">
      <div class="info" @click="clickTo">昨日用水量：<span>{{num}}t</span></div>
      <ChartLine @chartClick="chartClick" :ytitle="ytitle" :color="['#279FFE', '#445461']" :xdata="times" :ydata="lists"></ChartLine>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.info{
  position: absolute;color: #B8E9FC;
  left:6px;top:-10px;font-size:10px;
  cursor: pointer;
  span{
    font-size: 14px;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    color: #FFFFFF;
  }
}
.flex-center-container {
  width: 100%;
  height: 100%;
  .tabItem {
    position: absolute;
    top: 25px;
    left: 25px;
    z-index: 10000;
    background: transparent;
  }
  .chartItem {
    width: 100%;position: relative;
    height: calc(100% - 25px);
  }
}
</style>
