<template>
  <div class="chart-container">
    <v-chart v-on:click="btnClick" ref="lineChart" class="chart-container" :options="options"></v-chart>
  </div>
</template>

<script>
import Echarts from 'echarts'
export default {
  name: 'ChartLine',
  props: {
    ytitle: {
      type: String,
      default: ''
    },
    smooth: {
      type: Boolean,
      default: true
    },
    color: {
      type: Array,
      default: () => ['#138eff', '#d36218']
    },
    xdata: {
      type: Array,
      default: () => []
    },
    ydata: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      options: {}
    }
  },
  mounted() {
    this.getOption()
    window.addEventListener('resize', this.chartResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.chartResize)
  },
  computed: {
    lineChange() {
      let { ytitle, smooth, color, xdata, ydata } = this
      return { ytitle, smooth, color, xdata, ydata }
    }
  },
  watch: {
    lineChange() {
      this.getOption()
    }
  },
  methods: {
    btnClick(val) {
         //根据获取到的数据进行处理啦
        console.log(`图形的数据`,val)
        this.$emit('chartClick',val)
    },
    chartResize() {
      let chartInstance = Echarts.getInstanceByDom(this.$refs.lineChart.$el)
      chartInstance && chartInstance.resize()
    },
    getOption() {
      this.options = {
        color: this.color,
        grid: {
          top: 25,
          right: 15,
          bottom: 0,
          left: 10,
          containLabel: true
        },
        legend: {
          top: -5,
          right: 10,
          itemWidth: 16,
          itemHeight: 8,
          itemGap: 12,
          icon: 'rect',
          itemStyle: {
            borderColor: '#ffffff',
            borderWidth: 1
          },
          textStyle: {
            color: '#ffffff',
            fontSize: 10,
            lineHeight: 10
          },
          data: this.ydata.map((item) => ({
            name: item.name != '123' ? item.name : null
          }))
        },
        //设置悬浮时展示面板的样式
        tooltip: {
          trigger: 'axis',
          position: 'top',
          formatter: (params) => {
            let formatStr = params.length ? params[0].name : params[0].name != '123' ? params[0].name : ''
            let totalCount = 0.0
            params.forEach((item) => (totalCount += item.value))
            params.forEach((item) => {
              formatStr += `<br/>${item.seriesName != '' ? '' + item.value : ''}${item.seriesName}`
            })
            return formatStr
          },
          appendToBody: true,
          axisPointer: {
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.2)'
            }
          },
          extraCssText:
            'border: 2px solid; border-image: linear-gradient(180deg, #d7d7d7, #0083df 45%, #0083df 55%, #d7d7d7) 2 2; border-radius: 9px; text-align: left; padding: 8px 12px; font-size: 12px; line-height: 18px; color: box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.50), 0px 0px 26px 0px rgba(104,153,223,0.50) inset; background: #051619;'
        },
        //设置x轴
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: this.xdata,
            axisLine: {
              show: true,
              lineStyle: {
                color: 'rgba(255, 255, 255, 0.2)'
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              margin: 8,
              color: '#ffffff',
              fontSize: 12,
              lineHeight: 14
            },
            splitLine: {
              show: false
            }
          }
        ],
        // 设置y轴
        yAxis: [
          {
            name: this.ytitle,
            position: "left",
            nameGap: 10,
            nameTextStyle: {
              color: '#ffffff',
              fontSize: 12,
              lineHeight: 14
            },
            splitNumber: 3,
            minInterval: 1,
            boundaryGap: false,
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              margin: 10,
              color: '#76a8d4',
              fontSize: 12,
              lineHeight: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#1c6db1'
              }
            }
          },
        ],
        
          // {
          //   name: this.ytitle,
          //   nameGap: 10,
          //   position: "right",
          //   nameTextStyle: {
          //     color: '#ffffff',
          //     fontSize: 12,
          //     lineHeight: 14
          //   },
          //   splitNumber: 3,
          //   minInterval: 1,
          //   boundaryGap: false,
          //   axisLine: {
          //     show: false
          //   },
          //   axisTick: {
          //     show: false
          //   },
          //   axisLabel: {
          //     margin: 10,
          //     color: '#76a8d4',
          //     fontSize: 12,
          //     lineHeight: 14
          //   },
          //   splitLine: {
          //     show: true,
          //     lineStyle: {
          //       color: '#1c6db1'
          //     }
          //   }
          // }

          // yAxisIndex:index,
        series: this.ydata.map((item) => ({
          name: item.name != '123' ? item.name : '',
          type: 'line',
          //   stack: 'Total',
          smooth: this.smooth,
          symbol: 'circle',
          symbolSize: 8,
          hoverAnimation: false,
          data: item.data,
          itemStyle: {
            normal: {
              opacity: 0
            },
            emphasis: {
              borderColor: '#ffffff',
              borderWidth: 2,
              opacity: item.name != '123' ? 1 : 0
            }
          },
          areaStyle: {
            opacity: 0.3,
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#b0d9ff'
                },
                {
                  offset: 0.6,
                  color: '#b0d9ff'
                },
                {
                  offset: 1,
                  color: '#338ad0'
                }
              ]
            }
          }
        }))
      }
      




      
    }
  }
}
</script>
<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
}
</style>