<script>
import VueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "DeviceAlarmRecord",
  components: {
    VueSeamlessScroll,
  },
  data() {
    return {
      listData: [],
    };
  },
  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: this.listData.length, // 开始无缝滚动的数据量
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 32, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created(){
      this.getElectricityStatistical()
  },
  methods: {
    getElectricityStatistical() {
      this.$api.getbookList().then((res) => {
        if (res.code == "200") {
          console.log(res.data.records);
        //   this.listData.map((item)=>{
        //     //   item.startTime = item.startTime
        //   })
          this.listData=res.data.records

        }
      });
    },
  },
};
</script>

<template>
  <div class="bigscreen-chart-container">
    <div class="list-data-header">
      <label class="column serialno"> 事件名称 </label>
      <label class="column"> 预警时间 </label>
      <label class="column"> 预警位置 </label>
      <label class="column"> 状态 </label>
    </div>
    <vue-seamless-scroll
      :data="listData"
      class="list-data"
      style="margin: 0; padding: 0"
      :class-option="classOption"
    >
      <ul style="margin: 0; padding: 0">
        <li class="data-item" v-for="item in listData" :key="item.key">
          <label class="column serialno">
            {{ item.warnName }}
          </label>
          <label class="column">
            {{ item.startTime }}
          </label>
          <label class="column">
            {{ item.location }}
          </label>
          <label class="column">
            {{ item.warnStatus==1?'已处理':'未处理' }}
          </label>
        </li>
      </ul>
    </vue-seamless-scroll>
  </div>
</template>

<style lang="scss" scoped>
.list-data-header {
  display: flex;
  align-items: center;
  height: 16px;
  font-size: 12px;
  color: #b8e9fc;
  font-weight: bold;
  margin: 8px 0;
  .column {
    flex: 1;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 4px;
    &.serialno {
      width: 60px;
      flex: none;
    }
  }
}
.list-data {
  width: 100%;
  height: calc(100% - 32px);
  overflow: hidden;
  color: #B8E9FC;
  .data-item {
    font-size: 12px;
    height: 24px;
    display: flex;
    align-items: center;
    background: linear-gradient(
      180deg,
      rgba(50, 88, 126, 0.16) 0%,
      #133558 100%
    );
    border-radius: 2px;
    // background: rgba(50, 91, 166, 0.5);
    // border: 1px solid;
    // border-image: linear-gradient(
    // 		270deg,
    // 		rgba(46, 132, 219, 0) 3%,
    // 		rgba(46, 132, 219, 0.78) 62%,
    // 		rgba(46, 132, 219, 0.87)
    // 	)
    // 	1 1;
    margin-bottom: 10px;
    .column {
      flex: 1;
      width: 0;
      display: inline-block;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 4px;
      &.serialno {
        width: 60px;
        flex: none;
      }
    }
  }
}
</style>
