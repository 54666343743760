<script>
  /* 面板标题 */
  import { v4 as uuidv4 } from 'uuid'
  export default {
    name: 'PanelTitle',
    props: {
      width: {
        type: String,
        default: '100%'
      },
      title: {
        type: String,
        default: '面板标题'
      }
    },
    data() {
      return {
        uuid: ''
      }
    },
    mounted() {
      this.uuid = uuidv4().replace(/-/g, '')
    }
  }
</script>

<template>
  <div class="panel-title" :style="`width: ${width};`">
    <svg width="0" height="0">
      <defs>
        <linearGradient
          x1="25.9514375%"
          y1="55.231896%"
          x2="95.0556648%"
          y2="44.3478138%"
          :id="`decoration-fill-${uuid}`"
        >
          <stop stop-color="#000D15" stop-opacity="0" offset="0%"></stop>
          <stop stop-color="#003C73" offset="100%"></stop>
        </linearGradient>
        <linearGradient
          x1="28.4557309%"
          y1="50%"
          x2="81.5008136%"
          y2="40.7105813%"
          :id="`decoration-stroke-${uuid}`"
        >
          <stop stop-color="#001627" stop-opacity="0" offset="0%"></stop>
          <stop stop-color="#038BFF" offset="99.9410989%"></stop>
        </linearGradient>
      </defs>
    </svg>
    <label class="title-line">
      <span class="line-decoration" v-for="n in 3" :key="n"></span>
    </label>
    <svg
      class="title-decoration"
      width="22px"
      height="28px"
      viewBox="0 0 22 28"
    >
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g
          transform="translate(-79.000000, 0.000000)"
          :fill="`url(#decoration-fill-${uuid})`"
          :stroke="`url(#decoration-stroke-${uuid})`"
          stroke-width="2"
        >
          <g transform="translate(-0.000000, 1.000000)">
            <path
              d="M82.6735052,0 L93.4282754,0 C94.2884647,2.86074998e-16 95.0523042,0.550015084 95.3250643,1.36581382 L102.680704,23.3658138 C103.030956,24.4133814 102.465669,25.5465375 101.418102,25.8967888 C101.21365,25.9651466 100.999492,26 100.783916,26 L80.7638617,26 L80.1626495,26 C79.8456102,26 79.5885991,25.7429889 79.5885991,25.4259495 C79.5885991,25.4171061 79.5888034,25.4082638 79.589212,25.3994297"
              transform="translate(91.561437, 13.000000) scale(-1, 1) translate(-91.561437, -13.000000) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
    <label class="title-name">
      {{ title }}
    </label>
    <svg
      class="title-decoration mirror"
      width="22px"
      height="28px"
      viewBox="0 0 22 28"
    >
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g
          transform="translate(-79.000000, 0.000000)"
          :fill="`url(#decoration-fill-${uuid})`"
          :stroke="`url(#decoration-stroke-${uuid})`"
          stroke-width="2"
        >
          <g transform="translate(-0.000000, 1.000000)">
            <path
              d="M82.6735052,0 L93.4282754,0 C94.2884647,2.86074998e-16 95.0523042,0.550015084 95.3250643,1.36581382 L102.680704,23.3658138 C103.030956,24.4133814 102.465669,25.5465375 101.418102,25.8967888 C101.21365,25.9651466 100.999492,26 100.783916,26 L80.7638617,26 L80.1626495,26 C79.8456102,26 79.5885991,25.7429889 79.5885991,25.4259495 C79.5885991,25.4171061 79.5888034,25.4082638 79.589212,25.3994297"
              transform="translate(91.561437, 13.000000) scale(-1, 1) translate(-91.561437, -13.000000) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
    <label class="title-line mirror">
      <span class="line-decoration" v-for="n in 3" :key="n"></span>
    </label>
  </div>
</template>

<style lang="scss" scoped>
  .panel-title {
    height: 28px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    .title-name {
      white-space: nowrap;
      font-family: PangMenZhengDao;
      font-size: 20px;
      color: #c4f5ff;
    }
    .title-line {
      flex: 1;
      width: 0;
      display: inline-flex;
      flex-direction: column;
      position: relative;
      margin-right: -5px;
      &.mirror {
        margin-left: -5px;
        margin-right: 0;
        transform: rotateY(180deg);
      }
      .line-decoration {
        display: inline-block;
        width: 100%;
        height: 1px;
        background: linear-gradient(
          134deg,
          rgba(0, 30, 55, 0),
          rgba(0, 69, 135, 1)
        );
        &:not(:last-child) {
          margin-bottom: 3px;
        }
      }
    }
    .title-decoration {
      width: 22px;
      height: 28px;
      z-index: 1;
      &.mirror {
        transform: rotateY(180deg);
      }
    }
  }
</style>
