<script>
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'CenterViewSite',
  components: {
    // Swiper,
    // SwiperSlide
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 0,
        grabCursor: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      currentProgress: 0,
      mileData: [
        {
          milestoneNode: '施工许可',
          date: '2020-08-15',
          status: 'finish'
        },
        {
          milestoneNode: '基坑支护施工',
          date: '2020-08-16',
          status: 'finish'
        },
        {
          milestoneNode: '桩基施工',
          date: '2020-08-17',
          status: 'finish'
        },
        {
          milestoneNode: '主体开工',
          date: '2020-08-18',
          status: 'unfinish'
        },
        {
          milestoneNode: '结构±0',
          date: '2020-08-19',
          status: 'waiting'
        },
        {
          milestoneNode: '主体结构达到预售',
          date: '2020-08-20',
          status: 'waiting'
        },
        {
          milestoneNode: '样板房及展示区完成',
          date: '2020-08-21',
          status: 'waiting'
        },
        {
          milestoneNode: '主体结构封顶',
          date: '2020-08-22',
          status: 'waiting'
        },
        {
          milestoneNode: '外装落架',
          date: '2020-08-23',
          status: 'waiting'
        },
        {
          milestoneNode: '竣工备案',
          date: '2020-08-24',
          status: 'waiting'
        }
      ]
    }
  },
  props: {
    swiperData: {
      type: Array,
      default: () => [],
    },
    renderSettingData: {
      type: Array,
      default: () => [],
    }
  },
  created() {

  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiperInstance
    }
  },
  methods: {
    btnPrev() {
      this.swiper.slidePrev()
      this.currentProgress = this.swiper.progress
    },
    btnNext() {
      this.swiper.slideNext()
      this.currentProgress = this.swiper.progress
    },
    toBIM() {
      window.location.href = "/smartsite/bigscreen/bim-visual"
    }
  }
}
</script>

<template>
  <div class="full-container" style="position: relative;">
    <div class="site-container">
      <div class="site-background">
        <img class="image-container" src="../../assets/img/site.png" alt />
      </div>

      <!-- <a-button class="bimButton" @click="toBIM">可视化BIM</a-button> -->
    </div>
    <!-- v-for="(item, index) in swiperData"
    :key="index"-->
    <!-- <Swiper class="swiper-container" :options="swiperOption" ref="mySwiper">
      <SwiperSlide class="swiper-slide" v-for="(item, index) in swiperData" :key="index">
        <img class="image" :src="item.swiperImg" alt />
        <label class="name">{{ item.name }}</label>
        <a href="javascript:void(0)" class="item-dot">
          <i :class="`ali-icon ali-icon-status-${1}`"></i>
        </a>
        <label class="date">
          {{ item.time }}
          <span v-if="item.day" style="color:green;">提前{{item.day}}天</span>
        </label>
      </SwiperSlide>
    </Swiper>
    <div class="swiper-button-prev" slot="button-prev" @click="btnPrev"></div>
    <div class="swiper-button-next" slot="button-next" @click="btnNext"></div>-->

    <!-- <div class="bankuai">
      <div class="list">
        <div class="size">14</div>
        <div>项目开工数</div>
      </div>
      <div class="list">
        <div class="size">2</div>
        <div>项目完成数</div>
      </div>
      <div class="list">
        <div class="size">0</div>
        <div>年度重大事故</div>
      </div>
      <div class="list">
        <div class="size">18</div>
        <div>预售项目</div>
      </div>
    </div>-->
  </div>
</template>

<style lang="scss" scoped>
.site-container {
  // height: calc(100% - 118px);
  //   height: calc(100% - 218px);
  height: 100%;
  margin-bottom: 8px;
  padding: 2px;
  border-radius: 9px;
  background: linear-gradient(
    180deg,
    #427ec7,
    rgb(0, 98, 184) 35%,
    rgb(0, 98, 184) 65%,
    #427ec7
  );
  .site-background {
    // background-color: #0665bd;
    background: linear-gradient(90deg, #0D589D 0%, #0E4477 100%);
box-shadow: inset 0px 0px 10px 0px rgba(255, 255, 255, 0.5);
    padding: 5px;

    border-radius: 9px;
    width: 100%;
    height: 100%;
    .image-container {
      width: 100%;
      height: 100%;
      border-radius: 9px;
      cursor: pointer;
    }
  }

  .bimButton {
    // position: absolute;
    // z-index: 3;
    float: right;
    background-color: rgba($color: #1885be, $alpha: 0.8) !important;
    color: #ffffff;
    // right: 0;
    // bottom: calc(50% - 50px);
    border: none;
    margin-top: -35px;
    margin-right: 5px;
  }
}

.swiper-container {
  height: 110px;
  margin: 0 32px;
  position: relative;
  &:before {
    content: "";
    height: 4px;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 25px;
    background: rgba(216, 216, 216, 0.27);
    border-radius: 2px;
  }
}

.swiper-slide {
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition-property: all;
  font-size: 12px;
  line-height: 1;
  .image {
    width: 90px;
    height: 50px;
    border-radius: 2px;
    cursor: pointer;
  }
  .name {
    display: inline-block;
    width: 100%;
    margin: 6px 0;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 8px;
  }
  .item-dot {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 36px;
    width: 18px;
    height: 18px;
    background: radial-gradient(#0093f3, rgba(19, 59, 105, 0));
    border-radius: 50%;
    cursor: pointer;
    &:after {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: white;
      position: absolute;
    }
    .ali-icon {
      z-index: 9;
    }
  }
  .date {
    margin-top: 6px;
  }
}

.swiper-button-prev {
  display: inline-flex;
  width: 16px;
  height: 50px;
  align-items: center;
  justify-content: center;
  background: #2e538d;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: auto;
  bottom: 160px;
  &:after {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
  }
  &:hover {
    cursor: pointer;
    color: #ffffff;
    background: #4b6b99;
  }
}

.swiper-button-next {
  display: inline-flex;
  width: 16px;
  height: 50px;
  align-items: center;
  justify-content: center;
  background: #2e538d;
  border-radius: 2px;
  position: absolute;
  right: 0;
  top: auto;
  bottom: 160px;
  &:after {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
  }
  &:hover {
    cursor: pointer;
    color: #ffffff;
    background: #4b6b99;
  }
}

.bankuai {
  display: flex;
  align-items: center;
  justify-content: center;
  .list {
    text-align: center;
    width: 158px;
    margin: 10px 10px;
    height: 82px;
    opacity: 1;
    font-size: 12px;
    border-radius: 4px;
    background: linear-gradient(133deg, #00b1ff, rgba(0, 149, 255, 0.35) 98%);
    .size {
      font-size: 28px;
      font-weight: 400;
      text-align: center;
      margin-top: 10px;
      font-family: acens;
    }
  }
}
</style>
