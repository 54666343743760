<script>
	export default {
		name: 'CenterViewProject',
		props: {
			renderSettingData: {
				type: Array,
				default: () => [],
			},
		},
		data () {
			return {
				datas:{},
				url:'',		
			}
		},
		mounted(){
			console.log(this.renderSettingData)
			for(let i=0;i<this.renderSettingData.length;i++){
				if(this.renderSettingData[i].publicizePosition=='1'){
					this.datas= this.renderSettingData[i]
					if(this.renderSettingData[i].fileUrl){
						let imgList = JSON.parse(this.renderSettingData[i].fileUrl)
						if(imgList.length>0){
							console.log(imgList[0])
							this.url =imgList[0].url
							console.log(imgList[0].url)
							let types = this.checkFileType(imgList[0].url)
							console.log(types)
						}
					}
				}
			}
		},
		methods:{
			checkFileType(suffix) {
				var type="";
				switch (suffix) {
					case 'mp4': 
						type = 'mp4'; 
						break;
					case 'ppt': 
					case 'pptx':
					case 'doc':
					case 'docx':
					case 'pdf':
						type = 'office';
						break;
					case 'jpg':
					case 'jpeg':
					case 'png':
					case 'bmp':
					case 'gif':
						type = 'pic';
						break;
				}
				return type;
			}
		}
	}
</script>

<template>
	<div class="full-container">


		<div class="site-container">
			<div class="site-background">
				<p class="content" v-if="datas.contentText">
					{{datas.contentText}}
				</p>
				<img v-if="(url.includes('.jpg')||url.includes('.png'))" style="width:100%;border-radius: 9px;height:100%;" :src="url" alt="">
				<video v-if="url.includes('.mp4')||url.includes('.ogg')"  :src="url"  autoplay="autoplay" style="width:100%;height:100%;" ></video>
			</div>
		</div>



		
		<!-- <p class="content">
			本项目建筑结构超高、超限，塔楼结构在裙房顶层进行高位结构转换，裙房采用大跨度结构。塔楼需要进行装配式设计，采用现浇钢筋混凝土部分框支剪力墙结构；地下室及裙房采用现浇混凝土框架结构，裙房顶有覆土部分，采用井字梁结构或加腋大板结构。楼屋面采用现浇混凝土梁板结构。
		</p>
		<p class="content">
			1、旧改项目前期手续办理周期长，各地块开工时间不一致，需整体竣工交付，工期风险高。
			2、周边环境复杂，紧邻民居、祠堂、公园、高边坡等，控制环境影响难度大。
			3、地形起伏大，基坑超深结构复杂，土方达百万方，逢雨季施工，进度控制难度大。
			4、11栋超高层装配式建筑，同步开工体量大；精装交房，必须穿插流水施工，组织难度大。
		</p> -->
	</div>
</template>

<style lang="scss" scoped>
	.full-container {
		overflow-y: auto;
	}
	.content {
		text-indent: 28px;
		text-align: justify;
		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}


	.site-container {
  // height: calc(100% - 118px);
  //   height: calc(100% - 218px);
  height: 100%;
  margin-bottom: 8px;
  padding: 2px;
  border-radius: 9px;
  background: linear-gradient(
    180deg,
    #427ec7,
    rgb(0, 98, 184) 35%,
    rgb(0, 98, 184) 65%,
    #427ec7
  );
  .site-background {
    // background-color: #2F77C9;
    padding: 5px;
	background: linear-gradient(90deg, #0D589D 0%, #0E4477 100%);
box-shadow: inset 0px 0px 10px 0px rgba(255, 255, 255, 0.5);

    border-radius: 9px;
    width: 100%;
    height: 100%;
    .image-container {
      width: 100%;
      height: 100%;
      border-radius: 9px;
      cursor: pointer;
    }
  }

  .bimButton {
    // position: absolute;
    // z-index: 3;
    float: right;
    background-color: rgba($color: #1885be, $alpha: 0.8) !important;
    color: #ffffff;
    // right: 0;
    // bottom: calc(50% - 50px);
    border: none;
    margin-top: -35px;
    margin-right: 5px;
  }
}
</style>
