<script>
import { mapActions } from "vuex";
import KeyDeviceMonitor from "./KeyDeviceMonitor";
import KeyDeviceMonitor2 from "./KeyDeviceMonitor2";//人员统计
import CenterView from "./CenterView";
import EnvironmentMonitor from "./EnvironmentMonitor";//环境监测
import EnergyConsumMonitor from "./EnergyConsumMonitor";//用电
import EnergyConsumMonitor2 from "./EnergyConsumMonitor2";//用水
import EnergyConsumMonitor3 from "./EnergyConsumMonitor3";//进场趋势
import DeviceAlarmRecord from "./DeviceAlarmRecord";//进场趋势
import ImageProgress from "./ImageProgress";//进场趋势
import TitleDecoration from "@/components/TitleDecoration";
import PanelTitle from '@/components/custom/PanelTitle'

export default {
  name: "WorksiteBusiness",
  components: {
    KeyDeviceMonitor,
    KeyDeviceMonitor2,
    CenterView,
    EnvironmentMonitor,
    EnergyConsumMonitor,
    EnergyConsumMonitor2,
    EnergyConsumMonitor3,
    // MonitorView,
    TitleDecoration,
    PanelTitle,
    DeviceAlarmRecord,
    ImageProgress,
  },
  created() {
    this.QueryPicServer();
  },
  methods: {
    ...mapActions(["QueryPicServer"]),
  },
};
</script>

<template>
  <div class="bigscreen-container">
    <div class="container-column">
      <div style="padding:10px">
        <PanelTitle title="绿色营区" />
      </div>
      <div class="container-row">
        <TitleDecoration title="环境监测" />
        <div style="height: calc(100% - 24px);">
          <EnvironmentMonitor />
        </div>
      </div>
      <div class="container-row">
        <TitleDecoration title="用电监测" />
        <div style="height: calc(100% - 20px);">
           <EnergyConsumMonitor />
        </div>
      </div>
      <div class="container-row">
        <TitleDecoration title="用水监测" />
        <div style="height: calc(100% - 20px);">
          <EnergyConsumMonitor2 />
        </div>
      </div>
      <div class="container-row">
        <TitleDecoration title="设备监测" />
        <div style="height: calc(100% - 30px);">
            <KeyDeviceMonitor />
        </div>
      </div>
    </div>
    <div class="container-center">
      <div class="center-top">
        <CenterView />
      </div>
      <div class="center-bottom">
        <ImageProgress />
      </div>
    </div>
    <div class="container-column">
      <div style="padding:10px">
        <PanelTitle title="人员管理" />
      </div>
      <div class="container-row">
        <TitleDecoration title="人员统计" />
        <div style="height: calc(100% - 20px);">
            <KeyDeviceMonitor2 />
        </div>
      </div>
      <div class="container-row">
        <TitleDecoration title="进场趋势" />
        <div style="height: calc(100% - 20px);">
          <EnergyConsumMonitor3 />
        </div>
      </div>
      <div class="container-row" style="flex:2">
        <TitleDecoration title="出入台账" />
        <div style="height: calc(100% - 50px);">
					<DeviceAlarmRecord />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.center-top {
  // flex: 6;
  height: 65%;
}
.center-bottom {
  height: 35%;
  // flex: 4;
  display: flex;
  margin-top: 8px;
  .bottom-item {
    flex: 1;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.container-column {
  width: 25%;

  .container-row {
    flex: 1;
    height: 0;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.container-center {
  width: 0;
  width: 50%;
  margin: 0 8px;
}

.container-menu {
  flex: 1.5;
}
</style>