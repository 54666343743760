<script>
	export default {
		name: 'BaseCuboidBox',
		props: {
			// 方向，vertical-垂直、horizontal-水平
			direction: {
				type: String,
				default: 'vertical'
			},
			// 占比
			percent: {
				type: Number,
				default: 100
			},
			// 顶部颜色
			topColor: {
				type: String,
				default: '#c5c5c6'
			},
			// 左侧颜色
			leftColor: {
				type: Array,
				default: () => ['rgba(171, 171, 171, 0.08)', '#c1c2c3']
			},
			// 右侧颜色
			rightColor: {
				type: Array,
				default: () => ['rgba(171, 171, 171, 0.08)', '#dbdbdb']
			}
		}
	}
</script>

<template>
	<div
		:class="`cuboid-box ${direction}`"
		:style="
			direction == 'vertical'
				? `width: 16px; height: ${percent}%;`
				: `width: ${percent}%; height: 16px;min-width:10px;`
		"
	>
		<template v-if="direction == 'horizontal'">
			<div
				class="left-box"
				:style="
					`box-shadow: 0px -3px 10px 0px  ${leftColor[0]};`
				"
			></div>
		</template>
	</div>
</template>

<style lang="scss" scoped>
	.cuboid-box {
		position: relative;
		transition: width height 1.5s;
		&.vertical {
			.top-box-before {
				width: 16px;
				height: 0;
				border-bottom: 5px solid #c5c5c6;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
			}
			.top-box-after {
				width: 16px;
				height: 0;
				border-top: 5px solid #c5c5c6;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
			}
			.left-box {
				width: 8px;
				position: absolute;
				top: 10px;
				bottom: 0;
				left: 0;
				background: linear-gradient(
					0deg,
					rgba(171, 171, 171, 0.08) 5%,
					#c1c2c3
				);
				// transform: skew(0, 30deg);
				// transform-origin: right top;
			}
			.right-box {
				width: 8px;
				position: absolute;
				top: 10px;
				bottom: 0;
				right: 0;
				background: linear-gradient(
					0deg,
					rgba(171, 171, 171, 0.08) 5%,
					#dbdbdb
				);
				// transform: skew(0, -30deg);
				// transform-origin: left top;
			}
		}
		&.horizontal {
			text-align: right;
			.top-box-before {
				display: inline-block;
				width: 0;
				height: 16px;
				border-right: 5px solid #c5c5c6;
				border-top: 8px solid transparent;
				border-bottom: 8px solid transparent;
			}
			.top-box-after {
				display: inline-block;
				width: 0;
				height: 16px;
				border-left: 5px solid #c5c5c6;
				border-top: 8px solid transparent;
				border-bottom: 8px solid transparent;
			}
			.left-box {
				height: 12px;
				position: absolute;
				right: 10px;
				left: 0;
				top: 0;
				background: linear-gradient(
					90deg,
					rgba(171, 171, 171, 0.08) 2%,
					#c1c2c3
				);

                background: linear-gradient(90deg, #285F8A 0%,  19%, #339FFF 50%, #1AA0E7 80%, #FFFFFF 100%);
                box-shadow: 0px -3px 10px 0px #279FFE;
				// transform: skew(-30deg, 0);
				// transform-origin: right bottom;
			}
			.right-box {
				height: 8px;
				position: absolute;
				right: 10px;
				left: 0;
				bottom: 0;
				background: linear-gradient(
					90deg,
					rgba(171, 171, 171, 0.08) 2%,
					#dbdbdb
				);
				// transform: skew(30deg, 0);
				// transform-origin: right top;
			}
		}
	}
</style>
