<template>
  <div type="flex" class="vedeo-container">
    <div class="list-wrap">
      <!-- <BaseContentBox title="监控列表"> -->
      <div
        style="
          height: 100%;
          background: linear-gradient(
            180deg,
            rgba(10, 51, 71, 0) 0%,
            rgba(10, 51, 71, 0.41) 0%,
            rgba(46, 132, 219, 0.2) 100%
          );
        "
      >
        <div
          style="
            padding: 0 10px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <img
            style="width: 25px; height: 23px; margin-right: 11px"
            src="./img/jiankong-icon.png"
            alt=""
          />
          监控列表
        </div>
        <a-input
          v-model="searchValue2"
          class="search-input2"
          style="width: 200px; border-radius: 20px; margin-top: 10px"
          placeholder="搜索名称"
          allowClear
        />
        <!-- @change="(e) => treeSearch(e.target.value)" -->

        <div class="listBar scroll-container">
          <a-menu
            style="margin-top: 10px"
            mode="inline"
            theme="dark"
            :inline-collapsed="false"
          >
            <a-menu-item
              @click="selectChannel(item, key)"
              :key="item.id"
              v-for="(item, key) in searchList"
              style="display: flex; align-items: center"
            >
              <!-- style="margin-left: -20px" -->
              <a-tooltip>
                <template slot="title">{{ item.deviceName }}</template>
                <!-- <a-icon type="pie-chart" /> -->
                <img
                  style="width: 11px; height: 15px; margin-right: 13px"
                  :style="
                    item.platformValue.cameraType == 1
                      ? 'width: 11px'
                      : 'width: 17px'
                  "
                  :src="item.platformValue.cameraType == 1 ? qiuji : jiankong"
                  alt=""
                />

                <span
                  style="font-size: 14px; color: #fff"
                  v-if="item.deviceName.indexOf(searchValue2) > -1"
                >
                  {{
                    item.deviceName.substr(
                      0,
                      item.deviceName.indexOf(searchValue2)
                    )
                  }}
                  <span style="color: red">{{ searchValue2 }}</span>
                  {{
                    item.deviceName.substr(
                      item.deviceName.indexOf(searchValue2) +
                        searchValue2.length
                    )
                  }}
                </span>
                <span v-else style="color: #fff">{{ item.deviceName }}</span>

                <!-- <span>{{ item.deviceName }}</span> -->
              </a-tooltip>
            </a-menu-item>
            <!-- <a-menu-item key="2">
            <a-icon type="desktop" />
            <span>Option 2</span>
          </a-menu-item>
          <a-menu-item key="3">
            <a-icon type="inbox" />
            <span>Option 3</span>
          </a-menu-item> -->
          </a-menu>
        </div>
      </div>

      <!-- <div class="cameraList">
          <li
            @click="selectChannel(item, key)"
            v-for="(item, key) in cameraList"
            :key="key"
          >
            <a>{{ item.deviceName }}</a>
          </li>
        </div> -->

      <!-- <a-input
            class="search-input2"
            style="width: 200px;border-radius:20px;margin-left: 20px;margin-top:20px;"
            placeholder="搜索名称"
            @change="inputChange"
            allowClear v-model="deviceName" 
          />

        <a-menu mode="inline" style="margin-top: 10px; overflow: hidden">
          <a-sub-menu v-for="(item, key) in cameraList" :key="item.id">
            <span
              @click="changeClick(key)"
              slot="title"
              style="width: 100%; display: inline-block"
              ><span style="margin-left: 10px"
                >{{ item.deviceName }}</span
              ></span
            >
            <template v-if="item.children && item.children.length > 0">
              <a-menu-item v-for="(items, key2) in item.children" @click="selectChannel(items, key2,key)" :key="items.id">
                {{ items.deviceName }}
              </a-menu-item>
            </template>
          </a-sub-menu>
        </a-menu> -->

      <!-- <div class="search-content" style="margin-top: 20px">
          <a-input
            class="search-input"
            style="width: 200px; border-radius: 20px; margin-left: 10px"
            placeholder="搜索名称"
            allowClear
            @change="inputChange"
          />
          <div class="content-tree">
            <a-tree
              class="organ-tree"
              :show-line="true"
              :show-icon="true"
              :expandedKeys="expandedKeys"
              :tree-data="organStructure"
              :load-data="getMonitorDevice"
              @select="treeSelect"
              @expand="
                (keys) => {
                  expandedKeys = keys;
                }
              "
            >
              <i
                slot="group"
                class="ali-icon ali-icon-module-organ"
                style="color: #3cb5a1"
              ></i>
              <i
                slot="area"
                class="ali-icon ali-icon-module-area"
                style="color: #f7b500"
              ></i>
              <i
                slot="company"
                class="ali-icon ali-icon-module-company"
                style="color: #f7b500"
              ></i>
              <i
                slot="project"
                class="ali-icon ali-icon-module-project"
                style="color: #529aff"
              ></i>
              <i
                slot="device"
                slot-scope="{ cameraType, cameraStatus }"
                :class="[
                  'ali-icon',
                  `ali-icon-module-${
                    cameraType == 1 ? 'monitor-center' : 'ball-machine'
                  }`,
                ]"
                :style="`color: ${cameraStatus == 1 ? '#00c653' : '#ff3d3d'};`"
              >
              </i>
              <template slot="title" slot-scope="{ title }">
                <template v-if="title.indexOf(searchValue) > -1">
                  {{ title.substr(0, title.indexOf(searchValue)) }}
                  <span style="color: #f50">{{ searchValue }}</span>
                  {{
                    title.substr(
                      title.indexOf(searchValue) + searchValue.length
                    )
                  }}
                </template>
                <span v-else>{{ title }}</span>
              </template>
            </a-tree>
          </div>
        </div> -->
      <!-- </BaseContentBox> -->
    </div>

    <div class="body-container">
      <BaseContentBox title="监控视频" :width="300">
        <div id="playWnd" ref="playWnd" style="width: 100%; height: 100%"></div>

        <!-- 分屏设置 -->
        <!-- <div class="top-splitscreen">
          <a-select
            class="top-splitscreen-select"
            placeholder="分屏设置"
            :value="splitScreen"
            @change="
              (value) => {
                splitScreenChange(value, splitScreen);
              }
            "
          >
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option
              v-for="item in enumSplitScreenSet"
              :key="item.value"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </div> -->
        <!-- <div class="monitor-content">
          <div class="content-bottom">
            <div
              class="monitor-column"
              v-for="column in splitScreen"
              :key="column"
            >
              <div
                @click="
                  btnClick(dataScreen[(column - 1) * splitScreen + row - 1])
                "
                class="monitor-row"
                v-for="row in splitScreen"
                :key="row"
                :class="{
                  active: (column - 1) * splitScreen + row - 1 == selectScreen,
                }"
                :ref="`video_${(column - 1) * splitScreen + row - 1}`"
              >
                <iframe
                  v-if="dataScreen[(column - 1) * splitScreen + row - 1]"
                  class="videp-player"
                  :src="dataScreen[(column - 1) * splitScreen + row - 1]"
                ></iframe>
                <div
                  class="iframe-mask"
                  @click="
                    () => {
                      selectScreen = (column - 1) * splitScreen + row - 1;
                    }
                  "
                >
                  <template
                    v-if="dataScreen[(column - 1) * splitScreen + row - 1]"
                  >
                    <a-button
                      v-if="
                        fullScreenElement !=
                        $refs[
                          'video_' + ((column - 1) * splitScreen + row - 1)
                        ][0]
                      "
                      class="iframe-close"
                      size="small"
                      @click.stop="
                        closeVideo((column - 1) * splitScreen + row - 1)
                      "
                      >关闭</a-button
                    >
                    <div class="iframe-note">
                      <label class="note-name">{{
                        nameScreen[(column - 1) * splitScreen + row - 1]
                      }}</label>
                      <i
                        :class="`ali-icon ali-icon-action-${
                          fullScreenElement ==
                          $refs[
                            'video_' + ((column - 1) * splitScreen + row - 1)
                          ][0]
                            ? 'exitscreen'
                            : 'fullscreen'
                        }`"
                        @click.stop="
                          screenVideo((column - 1) * splitScreen + row - 1)
                        "
                      ></i>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </BaseContentBox>
    </div>
    <a-drawer
      title="云台控制"
      placement="right"
      :closable="false"
      :visible="visible"
      @close="onClose"
      width="300"
      :maskStyle="{ background: 'rgba(0,0,0,0)' }"
    >
      <div @click="onClose" class="guanbi">
        <a-icon style="color: white" type="close" />
      </div>
      <div class="video-control">
        <!-- 按钮控制 -->
        <div class="control-c">
          <div class="box">
            <div>
              <img
                @click="PtzFocusDowns(11)"
                class="nearFocus"
                src="@/assets/image/shipin/FocusDown_d.png"
                alt
              />
            </div>
            <div class="text-center">
              <span>变焦</span>
            </div>

            <div class="text-right">
              <img
                src="@/assets/image/shipin/FocusUp_d.png"
                class="farFocus"
                @click="PtzFocusDowns(10)"
                alt
              />
            </div>
          </div>
          <div class="box">
            <div>
              <img
                src="@/assets/image/shipin/scaleMinus_d.png"
                class="narrow"
                @click="PtzFocusDowns(9)"
                alt
              />
            </div>
            <div class="text-center">
              <span>变倍</span>
            </div>

            <div class="text-right">
              <img
                src="@/assets/image/shipin/scaleAdd_d.png"
                class="enlarge"
                @click="PtzFocusDowns(8)"
                alt
              />
            </div>
          </div>
          <div class="box">
            <div>
              <img
                src="@/assets/image/shipin/ApertureDown_d.png"
                class="stopDown"
                @click="PtzFocusDowns(12)"
                alt
              />
            </div>

            <div class="text-center">
              <span>光圈</span>
            </div>
            <div class="text-right">
              <img
                src="@/assets/image/shipin/ApertureUp_d.png"
                class="stopUp"
                @click="PtzFocusDowns(13)"
                alt
              />
            </div>
          </div>
        </div>
        <!-- 滑块控制 -->
        <div class="control-d">
          <div>
            <div class="contrl_back">
              <img src="@/assets/image/shipin/control_back.png" alt />
            </div>
            <img
              src="@/assets/image/shipin/up_disabled.png"
              @mousedown="PtzDirection(0)"
              @mouseup="PtzDirectionStop(0)"
              class="up"
              alt
            />
            <img
              src="@/assets/image/shipin/down_disabled.png"
              @mousedown="PtzDirection(1)"
              @mouseup="PtzDirectionStop(1)"
              class="down"
              alt
            />
            <img
              src="@/assets/image/shipin/left_disabled.png"
              @mousedown="PtzDirection(2)"
              @mouseup="PtzDirectionStop(2)"
              class="left"
              alt
            />
            <img
              src="@/assets/image/shipin/right_disabled.png"
              @mousedown="PtzDirection(3)"
              @mouseup="PtzDirectionStop(3)"
              class="right"
              alt
            />
          </div>
        </div>
        <!-- 按钮块控制 -->
        <div class="control-p">
          <div class="box">
            <div>
              <li @click="play()">
                <img src="@/assets/image/shipin/icon_splj.png" alt />
                <p>播放</p>
              </li>
            </div>
            <div>
              <li @click="stopPlay()">
                <img src="@/assets/image/shipin/icon_pause.png" alt />
                <p>停止</p>
              </li>
            </div>
          </div>
          <div class="box">
            <div>
              <li @click="CapturePicture()">
                <img src="@/assets/image/shipin/icon_jt.png" alt />
                <p>抓图</p>
              </li>
            </div>
            <div>
              <li id="record">
                <img
                  state="false"
                  src="@/assets/image/shipin/icon_lx.png"
                  alt
                />
                <p>录像</p>
                <!-- <img
                  src="@/assets/image/shipin/play.gif"
                  alt=""
                  id="recordActive"
                />-->
              </li>
            </div>
          </div>
          <div style="text-align: center; margin: 20px 0; color: #ffffff">
            <p>码流</p>
          </div>
          <div class="box stream-type" id="streamType">
            <div class="item" @click="changeType(0)">
              <li type="1" :class="typeStatus == 0 ? 'control_active' : ''">
                <span>主码流</span>
              </li>
            </div>
            <div class="item" @click="changeType(1)">
              <li
                class
                :class="typeStatus == 0 ? '' : 'control_active'"
                type="2"
              >
                <span>辅码流</span>
              </li>
            </div>
          </div>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
var oWebControl = null;
// // var accessToken = '';

/**
 * video 初始化及配置
 * @param {*video挂载的dom id} id
 * @param {*连接成功的回调} cbConnectSuccess
 * @param {*连接报错的回调} cbConnectError
 * @param {*连接关闭的回调} cbConnectClose
 */
function WebControlInit(id, cbConnectSuccess, cbConnectError, cbConnectClose) {
  return new WebControl({
    szPluginContainer: id,
    iServicePortStart: 14510, // 对应 LocalServiceConfig.xml 中的ServicePortStart值
    iServicePortEnd: 14519, // 对应 LocalServiceConfig.xml 中的ServicePortEnd值
    cbConnectSuccess: cbConnectSuccess,
    cbConnectError: cbConnectError,
    cbConnectClose: cbConnectClose,
  });
}
/**
 * 离开页面时调用 断开链接的方法
 * @param {*视频插件实例} oWebControl
 */
function WebControlDistory() {
  if (oWebControl != null) {
    oWebControl.JS_DestroyWnd().then(
      function () {
        console.log("JS_DestroyWnd");
      },
      function () {}
    );
    oWebControl.JS_StopService("window").then(function () {
      oWebControl.JS_Disconnect().then(
        function () {
          console.log("JS_Disconnect");
        },
        function () {}
      );
    });
  }
}
// 监听视频控件的事件
function cbIntegrationCallBack(oData) {
  // app.showCBInfo(oData);
  if (oData.responseMsg.eventName === "FireTransFunction") {
    // 如果视频控件需要请求服务端数据，这里可以处理透传逻辑
  }
}

function cbConnectSuccess() {
  var ele = document.getElementById("playWnd");
  console.log(ele.offsetWidth); // 空字符串
  console.log(ele.offsetHeight); // '100px'

  // 设置窗口控制回调
  oWebControl.JS_SetWindowControlCallback({
    cbIntegrationCallBack: cbIntegrationCallBack,
  });
  //创建视频窗口
  oWebControl
    .JS_StartService("window", {
      dllPath: "./chain/cloudTransform.dll",
    })
    .then(function () {
      oWebControl
        .JS_CreateWnd("playWnd", ele.offsetWidth, ele.offsetHeight)
        .then(function () {
          console.log("JS_CreateWnd success");
          // initVideo();
        });
    });
}
/**
 * 设置视频初始化参数
 */
// function initVideo() {
//   var layout;
//   var length = videoList.length;
//   if (length == 1) {
//     layout = 1;
//   } else if (length <= 4) {
//     layout = 2;
//     length = 4;
//   } else if (length > 4) {
//     layout = 6;
//     length = 9;
//   }
//   // 初始化入参
//   var argumentsPram = {
//     layout: layout,
//     userName: "",
//     waterMark: "",
//     intelligenceEnable: 1,
//     isRecord: 1,
//     isSetPos: 1,
//     motionDetectEnable: 0,
//     playBackAlarmOverlyingEnable: 0,
//     response: {
//       code: 0,
//       message: null,
//       data: {
//         appKey: videoAccount.appKey,
//         ezvizToken: videoAccount.ioToken,
//         videoLevel: 0,
//         showMainTool: 1,
//         showSubTool: 0,
//         waterMark: "0",
//         userName: "TestWaterMark",
//         platformId: "",
//       },
//     },
//   };
//   //   console.log('初始化入参', argumentsPram);
//   // 调用视频控件初始化方法
//   oWebControl
//     .JS_RequestInterface({
//       funcName: "Init",
//       arguments: encodeURI(JSON.stringify(argumentsPram)),
//     })
//     .then(function (oData) {
//       //   self.showCBInfo(oData.responseMsg);
//       //   console.log(oData.responseMsg, '视频初始化成功！');
//       if (videoList && videoList.length > 0) {
//         setTimeout(function () {
//           playVideo(videoList[0].deviceId);
//         }, 2000);
//       }
//     });
// }

function cbConnectError() {
  oWebControl = null;
  alert("请确认是否已安装青柠云web插件并本地进程开启成功！");
  console.log("执行");
  window.open(
    "http://oct-zhaoqing.tsingning.com/pages/download/cloudViewSetup.exe"
  );
  // console.log('cbConnectError');
  // oWebControl = null;
  // console.error('确认本地进程是否已安装并开启成功！');
}
function cbConnectClose() {
  // bNormalClose
  // 连接异常断开：bNormalClose = false
  // JS_Disconnect正常断开：bNormalClose = true
  console.log("cbConnectClose");
  oWebControl = null;
}
window.onscroll = function () {
  if (oWebControl != null) {
    oWebControl.JS_Resize(900, 500);
  }
};
window.onresize = function () {
  if (oWebControl != null) {
    oWebControl.JS_Resize(900, 500);
  }
};

window.onunload = function () {
  try {
    oWebControl.JS_HideWnd();
    WebControlDistory();
  } catch (error) {
    console.error(error);
  }
};
window.onpagehide = function () {
  try {
    oWebControl.JS_HideWnd();
  } catch (error) {
    console.error(error);
  }
};

import qiuji from "./img/qiuji-icon.png";
import jiankong from "./img/jiankong-icon.png";
import BaseContentBox from "@/components/BaseContentBox";
import { enumSplitScreenSet } from "@/utils/EnumUtils";
export default {
  components: { BaseContentBox },
  data() {
    return {
      qiuji: qiuji,
      jiankong: jiankong,
      ocx: {},
      cameraList: [],
      iframeurl: "",
      accessToken: "",
      index: 0,
      splitScreen: 3,
      dataScreen: ["", "", "", "", "", "", "", "", ""],
      nameScreen: ["", "", "", "", "", "", "", "", ""],
      selectScreen: 0,
      enumSplitScreenSet: enumSplitScreenSet,
      fullScreenElement: null,
      visible: false,
      deviceSerial: "",
      typeStatus: 0,
      expandedKeys: [],
      organStructure: [],
      searchValue: "",
      rootSubmenuKeys: [],
      openKeys: [],
      deviceName: "",
      searchValue2: "",
      channelId: "",
      accessToken2: "",
      channelNo: "",
      screenWidth: document.body.clientWidth,
      screenHeight:document.body.clientHeight,
      switchVideo: 0, // 0实时预览 1录像回放
    };
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.screenWidth);
          that.timer = false;

          oWebControl.JS_Resize(
            document.getElementById("playWnd").clientWidth,
            document.getElementById("playWnd").clientHeight
          );

          // var ele = document.getElementById('playWnd');
          // oWebControl.JS_CreateWnd('playWnd', ele.offsetWidth, ele.offsetHeight).then(function () {
          //   console.log('JS_CreateWnd success');
          // });
          // cbConnectSuccess()
          // oWebControl = WebControlInit(
          //   'playWnd',
          //   cbConnectSuccess,
          //   cbConnectError,
          //   cbConnectClose
          // );
        }, 400);
      }
    },
    
    screenHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.screenHeight);
          that.timer = false;

          oWebControl.JS_Resize(
            document.getElementById("playWnd").clientWidth,
            document.getElementById("playWnd").clientHeight
          );

          // var ele = document.getElementById('playWnd');
          // oWebControl.JS_CreateWnd('playWnd', ele.offsetWidth, ele.offsetHeight).then(function () {
          //   console.log('JS_CreateWnd success');
          // });
          // cbConnectSuccess()
          // oWebControl = WebControlInit(
          //   'playWnd',
          //   cbConnectSuccess,
          //   cbConnectError,
          //   cbConnectClose
          // );
        }, 400);
      }
    },
  },
  created() {
    // this.$api.deviceList({}).then((res) => {
    //   for (let i = 0; i < res.data.length; i++) {
    //     res.data[i].children = [];
    //   }
    //   this.cameraList = res.data;
    //   console.log(this.cameraList);
    // });
    this.$api.getCameraList({}).then((res) => {
      res.data.map((item) => {
        item.platformValue = JSON.parse(item.platformValue);
      });
      this.cameraList = res.data;
      console.log(this.cameraList);
    });
    // this.monitoringList();
  },
  mounted() {
    if (document.documentElement.onfullscreenchange) {
      window.addEventListener("fullscreenchange", this.fullScreenChange);
    } else if (document.documentElement.onwebkitfullscreenchange) {
      window.addEventListener("webkitfullscreenchange", this.fullScreenChange);
    } else if (document.documentElement.onmozfullscreenchange) {
      window.addEventListener("mozfullscreenchange", this.fullScreenChange);
    } else if (document.documentElement.onmsfullscreenchange) {
      window.addEventListener("msfullscreenchange", this.fullScreenChange);
    }

    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        window.screenHeight = document.body.clientHeight;

        that.screenWidth = window.screenWidth;
        that.screenHeight = window.screenHeight;


      })();
    };

    oWebControl = WebControlInit(
      "playWnd",
      cbConnectSuccess,
      cbConnectError,
      cbConnectClose
    );
    // console.log(oWebControl)
  },
  destroyed() {
    oWebControl.JS_HideWnd();
    WebControlDistory();

    if (document.documentElement.onfullscreenchange) {
      window.removeEventListener("fullscreenchange", this.fullScreenChange);
    } else if (document.documentElement.onwebkitfullscreenchange) {
      window.removeEventListener(
        "webkitfullscreenchange",
        this.fullScreenChange
      );
    } else if (document.documentElement.onmozfullscreenchange) {
      window.removeEventListener("mozfullscreenchange", this.fullScreenChange);
    } else if (document.documentElement.onmsfullscreenchange) {
      window.removeEventListener("msfullscreenchange", this.fullScreenChange);
    }
  },
  computed: {
    searchList () {
      if (!this.searchValue2) {
        return this.cameraList
      }
      return this.cameraList.filter(item => {
      	return item.deviceName.includes(this.searchValue2)
      })
    }
  },
  methods: {
    inputChange() {
      this.changeClick(0);
    },
    changeClick(e) {
      console.log(this.cameraList[e]);

      this.$api
        .monitoringList({
          deviceName: this.deviceName,
          tenantOrgId: this.cameraList[e].tenantOrgId,
        })
        .then((res) => {
          if (res.code == 200) {
            console.log("res", res);
            this.cameraList[e].children = res.data;
            console.log(this.cameraList);
          }
        });
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    changeList(e) {
      console.log(e);
    },
    btnClicknav(key) {
      console.log(key);
    },
    monitoringList() {
      this.$api.monitoringList().then((res) => {
        if (res.code == 200) {
          console.log("res", res);
          this.cameraList = res.data;
        }
      });
    },
    selectChannel(item, index) {
      const that = this;
      let items = item.platformValue;
      console.log(items);
      this.$api
        .loginCamera({
          clientId: items.clientId,
          clientSecret: items.clientSecret,
        })
        .then((response) => {
          if (response.code == 200) {
            // 初始化入参
            let argumentsPrambos = {
              // layout: 6,
              userName: "",
              waterMark: "",
              intelligenceEnable: 1,
              isRecord: 1,
              isSetPos: 1,
              motionDetectEnable: 0,
              playBackAlarmOverlyingEnable: 0,
              response: {
                code: 0,
                message: null,
                data: {
                  appKey: response.data.appKey,
                  ezvizToken: response.data.token,
                  videoLevel: 2,
                  showMainTool: 1,
                  showSubTool: 1,
                  waterMark: "1",
                  userName: "",
                  platformId: "",
                },
              },
            };
            console.log("初始化入参", argumentsPrambos);
            // 调用视频控件初始化方法
            oWebControl
              .JS_RequestInterface({
                funcName: "Init",
                arguments: encodeURI(JSON.stringify(argumentsPrambos)),
              })
              .then(function () {
                // self.showCBInfo(oData.responseMsg);
                // self.showTips(true, '视频初始化成功！');
                console.log("初始化成功");
                // 预览入参  ---- 播放
                let items = that.cameraList[index].platformValue;
                console.log(items);
                let argumentsPram = {
                  response: {
                    code: 0,
                    message: null,
                    data: {
                      deviceSerial: items.serialNumber,
                      channelCode: +items.channelNo,
                      channelNo: +items.channelNo,
                      codeIsEncrypt: 0,
                      validateCode: "",
                      deviceClass: "0",
                      deviceType: "10242",
                      //deviceType:"10240",
                      channelId: "2222222222",
                      channelName: "channelNameTest",
                      storeName: "storeName",
                      storeId: "storeid",
                      //startTime:'2021-03-21 01:00:00',
                      //endTime:'2021-03-21 01:02:00',
                      //Limited_start:limitStart,
                      //Limited_end:limitEnd,
                    },
                  },
                };
                // if (self.deviceClass === '1') { // 国标协议对应的两个字段
                //   argumentsPram.response.data.channelCode = self.channelNo
                //   argumentsPram.response.data.platformId = self.platformId
                // } else{ // 海康协议对应的一个字段
                //   argumentsPram.response.data.channelNo = +self.channelNo
                // }
                // 回放时对应的两个字段
                if (that.switchVideo === 1) {
                  if (that.startTime)
                    argumentsPram.response.data.startTime = that.startTime;
                  if (that.endTime)
                    argumentsPram.response.data.endTime = that.endTime;
                }
                console.log("预览/回放入参", argumentsPram);
                // 调用视频控件预览方法
                oWebControl
                  .JS_RequestInterface({
                    funcName:
                      that.switchVideo === 0 ? "StartPreview" : "StartPlayback",
                    arguments: encodeURI(JSON.stringify(argumentsPram)),
                  })
                  .then(function (oData) {
                    console.log("播放成功", oData);
                    // self.showCBInfo(oData.responseMsg);
                    // self.showTips(true, '预览/回放成功！');
                  });
              });
            console.log("Asdsaddasdsa>>>");

            // console.log("res", res);
            // this.accessToken = res.data.token;
            // this.accessToken2 = res.data.Authorization;
            // console.log("accessToken", this.accessToken);
            // // 调整到这里
            // this.index = index;
            // // let items = JSON.parse(this.cameraList[index].platformValue);
            // console.log(items);
            // this.deviceSerial = items.serialNumber;
            // this.channelId = items.channelId;
            // this.channelNo =items.channelNo
            // this.dataScreen[
            //   this.selectScreen
            // ] = `/work-site/ezuikit_v3.4/index.html?audio=0&accessToken=${
            //   this.accessToken
            // }&hd=1&deviceSerial=${items.serialNumber}&channelNo=${parseInt(
            //   items.channelNo
            // )}&channelId=${items.channelId}&accessToken2=${res.data.Authorization}&cameraType=${items.cameraType}`;
            // this.nameScreen[this.selectScreen] =
            //   this.cameraList[this.index].deviceName;
            // let newSelect = 0;
            // if (this.selectScreen < this.dataScreen.length - 1) {
            //   newSelect = this.selectScreen + 1;
            // }
            // this.selectScreen = -1;
            // this.$nextTick(() => {
            //   this.selectScreen = newSelect;
            //   // this.saveUserHabit()
            // });
          }
        });
    },
    queryCameraList() {
      let params = {
        storeId: "d43786b9fe2f4460a6bc0f7fc0320edd",
        pageNo: 1,
        pageSize: 30,
      };
      this.$api.queryCameraLists(params).then((res) => {
        if (res.code === 200) {
          this.cameraList = res.data.rows;
          this.$api.queryCameraAccountInfo().then((res) => {
            if (res.code === 200) {
              this.accessToken = res.data.token;
              this.iframeurl =
                "https://open.ys7.com/ezopen/h5/iframe_se?url=ezopen://BDZYWK@open.ys7.com/" +
                this.cameraList[this.index].deviceSerial +
                "/" +
                this.cameraList[this.index].channelNo +
                ".live" +
                "&accessToken=" +
                res.data.token;
              console.log("88888>", this.iframeurl);
            }
          });
        }
      });
    },
    btnClick(data) {
      console.log(data);
      if (data) {
        // cameraType
        if (this.getQueryVariable("cameraType", data) == 1) {
          this.visible = true;
        }
        this.channelId = this.getQueryVariable("channelId", data);
        this.channelNo = this.getQueryVariable("channelNo", data);
        this.deviceSerial = this.getQueryVariable("deviceSerial", data);
        // this.accessToken2= this.getQueryVariable('accessToken2',data)
        console.log(this.channelId);
      }
    },
    getQueryVariable(variable, url) {
      var query = url.split("?")[1];
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },

    onSearch() {},

    onClose() {
      this.visible = false;
    },

    saveUserHabit() {
      //   CommonApi.saveUserHabit({
      //     deployCode: "MonitorCenter",
      //     itemValue: JSON.stringify({
      //       splitScreen: this.splitScreen,
      //       dataScreen: this.dataScreen,
      //       selectScreen: this.selectScreen,
      //     }),
      //   });
    },
    splitScreenChange(nVal, oVal) {
      let arr = [...this.dataScreen];
      if (nVal > oVal) {
        for (let i = 0; i < nVal * nVal - oVal * oVal; i++) {
          arr.push("");
        }
      } else {
        for (let i = 0; i < oVal * oVal - nVal * nVal; i++) {
          arr.pop();
        }
      }
      this.splitScreen = nVal;
      this.dataScreen = arr;
      this.selectScreen = 0;
      //   this.saveUserHabit();
    },
    closeVideo(index) {
      this.dataScreen[index] = undefined;
      this.nameScreen[index] = "";
      let newSelect = 0;
      if (index < this.dataScreen.length) {
        newSelect = index;
      }
      this.selectScreen = -1;
      this.$nextTick(() => {
        this.selectScreen = newSelect;
        // this.saveUserHabit();
      });
    },
    screenVideo(index) {
      let domId = `video_${index}`;
      let ele = this.$refs[domId][0];
      let fullScreen = (
        ele.requestFullscreen ||
        ele.webkitRequestFullscreen ||
        ele.mozRequestFullScreen ||
        ele.msRequestFullscreen
      ).bind(ele);
      let exitFullScreen = (
        document.exitFullscreen ||
        document.webkitExitFullscreen ||
        document.mozCancelFullScreen ||
        document.msExitFullscreen
      ).bind(document);
      let eleFullScreen =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement;
      if (eleFullScreen == ele) {
        exitFullScreen();
      } else {
        fullScreen();
      }
    },

    // 控制
    play() {
      console.log("播放");
    },
    stopPlay() {
      let params = {
        channelId: this.channelId,
        direction: "0",
        token: this.accessToken2,
      };
      this.$api.customizationStop(params).then((response) => {
        console.log(response);
      });
    },
    CapturePicture() {
      let params = {
        channelNo: this.channelNo, //通道ID
        deviceSerial: this.deviceSerial,
        quality: 1,
        token: this.accessToken2, //accessToken
      };
      this.$api.customizationCapture(params).then((response) => {
        console.log(response);
        // upload
        //  this.downloadIamge(response.data.picUrl, "监控图片");
        window.open(response.data.picUrl);
      });
    },
    downloadIamge(imgsrc, name) {
      //下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },
    PtzFocusDowns(index) {
      console.log(index);
      let params = {
        channelId: this.channelId, //通道ID
        direction: index, //操作命令： 0-上，1-下，2-左，3-右，4-左上，5-左下，6-右上，7-右下，8-放大，9-缩小，10-近焦距，11-远焦距，12-启动光圈，13-缩小光圈，14-亮灯，15-雨刷，16-自动
        speed: 1, //当模式mode为0时：云台速度： 0-慢，1-适中，2-快；当模式mode为1时：云台速度：0-7
        mode: this.typeStatus, //模式传空默认是0，mode只有0,1两种状态
        token: this.accessToken2, //accessToken
      };
      this.$api.customizationStart(params).then((response) => {
        console.log(response);
      });
    },
    PtzDirection(index) {
      console.log(index);
      let params = {
        channelId: this.channelId, //通道ID
        direction: index, //操作命令： 0-上，1-下，2-左，3-右，4-左上，5-左下，6-右上，7-右下，8-放大，9-缩小，10-近焦距，11-远焦距，12-启动光圈，13-缩小光圈，14-亮灯，15-雨刷，16-自动
        speed: 1, //当模式mode为0时：云台速度： 0-慢，1-适中，2-快；当模式mode为1时：云台速度：0-7
        mode: this.typeStatus, //模式传空默认是0，mode只有0,1两种状态
        token: this.accessToken2, //accessToken
      };
      this.$api.customizationStart(params).then((response) => {
        console.log(response);
      });
    },
    PtzDirectionStop(index) {
      console.log(index);
      let params = {
        channelId: this.channelId,
        direction: index,
        token: this.accessToken2,
      };
      this.$api.customizationStop(params).then((response) => {
        console.log(response);
      });
    },
    changeType(index) {
      this.typeStatus = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.vedeo-container {
  display: flex;
  height:100%;
//   height: calc(100% - 104px);
  padding: 10px;
  margin-top: 8px;

  .list-wrap {
    width: 240px;
    background: linear-gradient(
      180deg,
      rgba(10, 51, 71, 0) 0%,
      rgba(10, 51, 71, 0.41) 0%,
      rgba(46, 132, 219, 0.2) 100%
    );
    padding: 20px;

    .cameraList {
      height: 450px;
      overflow-y: auto;
      overflow-x: hidden;
      box-sizing: border-box;
      padding: 10px 10px;
      color: #afb9cb;
      li {
        list-style-type: none;
        text-align: center;
        height: 36px;
        line-height: 36px;
        background: rgba(100, 149, 237, 0.5);
        background-color: #1a3669;
        margin-bottom: 10px;
        border-radius: 2px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 8px;
        text-align: center;
        a {
          color: #afb9cb;
        }
        a:hover {
          color: white;
        }
      }
    }
  }
  .vedio-wrap {
    width: calc(100% - 260px);
    height: calc(100% - 40px);
    background: black;
    margin: 0px 10px 10px 10px;
  }
}
.sskClass {
  margin: 40px auto 20px auto;
}
.guanbi {
  position: absolute;
  right: 30px;
  top: 30px;
}
.body-container {
  width: calc(100% - 260px);
  height: 100%;
  margin-left: 16px;
  background: linear-gradient(
    180deg,
    rgba(10, 51, 71, 0.1),
    rgba(10, 51, 71, 0.4),
    rgba(46, 132, 219, 0.2)
  );
  // background: url("../../assets/image/bg-image/bg-content.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  .child-page-container {
    //   padding: 60px 40px 0px 40px;

    padding: 20px 40px 0px 40px;
    padding-bottom: 10px;
    height: calc(100% - 40px);
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .child-page-title {
    position: absolute;
    top: 32px;
    left: 50px;
    font-size: 16px;
    // color: rgba(82,154,255,1);

    display: inline-block;
    font-weight: bold;
    letter-spacing: 2px;
    span {
      color: rgb(82, 154, 255);
    }
    .back {
      margin-right: 16px;
      font-size: 15px;
      color: rgba(82, 154, 255, 0.75);
    }
    .title-before {
      &::before {
        content: "";
        width: 9px;
        display: inline-block;
        height: 9px;
        // background: #84ffe9;
        background: #529aff;
        margin-right: 8px;
        border-radius: 1px;
      }
    }
  }
}
.top-splitscreen {
  //   position: absolute;
  float: right;
  margin-top: 10px;
  top: 30px;
  right: 20px;
  .top-splitscreen-select {
    width: 90px;
  }
}
.monitor-content {
  clear: both;
  width: 100%;
  height: calc(100% - 50px);
  padding: 20px;
  display: flex;
  flex-direction: column;
  .content-bottom {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: -10px;
    overflow: hidden;
    .monitor-column {
      flex: 1;
      display: flex;
      overflow: hidden;
      &:not(:last-child) {
        margin-bottom: 5px;
      }
      .monitor-row {
        flex: 1;
        display: flex;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        position: relative;
        overflow: hidden;
        border: 1px solid transparent;
        &.active {
          border: 1px solid #529aff;
        }
        &:not(:last-child) {
          margin-right: 5px;
        }
        .iframe-mask {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 9;
          &:hover {
            .iframe-close,
            .iframe-note {
              opacity: 1;
              transition: opacity 1s;
            }
          }
          .iframe-close {
            opacity: 0;
            transition: opacity 1s;
            position: absolute;
            top: 5px;
            right: 5px;
          }
          .iframe-note {
            opacity: 0;
            transition: opacity 1s;
            position: absolute;
            width: 100%;
            height: 32px;
            bottom: 0;
            padding: 0 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: rgba(0, 0, 0, 0.5);
            font-weight: bold;
            .note-name {
              flex: 1;
              display: inline-flex;
              overflow: hidden;
              margin-right: 8px;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .ali-icon {
              color: rgba(255, 255, 255, 0.5);
              &:hover {
                color: #ffffff;
                cursor: pointer;
              }
            }
          }
        }
        .videp-player {
          width: 100%;
          height: 100%;
          border: none;
          border-radius: 2px;
          position: absolute;
          z-index: 1;
        }
        .video-desc {
          width: 100%;
          height: 100%;
          position: absolute;
          .desc-top-left {
            position: absolute;
            top: 15px;
            left: 15px;
            display: inline-flex;
            flex-direction: column;
            label {
              font-size: 12px;
              font-weight: bold;
              color: #ffffff;
              line-height: 14px;
              &:not(:last-child) {
                margin-bottom: 4px;
              }
            }
          }
          .desc-play-button {
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -18px 0 0 -18px;
            font-size: 36px;
            color: rgba(255, 255, 255, 0.56);
            &:hover {
              cursor: pointer;
              color: #ffffff;
            }
          }
          .desc-bottom {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 32px;
            text-align: center;
            line-height: 32px;
            background: rgba(0, 0, 0, 0.7);
            font-size: 14px;
            font-weight: bold;
            color: #ffffff;
          }
        }
      }
    }
  }
}
.page {
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
}
.content {
  height: 98%;
  min-height: 750px;
}

.content > .box {
  height: 100%;
  min-height: 750px;
}

.content > .box > div {
  background: rgba(5, 12, 39, 0.3);
  box-shadow: 0px 1px 13px 0px rgba(70, 90, 201, 0.48);
  border: 1px solid rgba(85, 143, 255, 0.2);
}

.screen-wrap {
  position: absolute;
  top: -30px;
  right: 44px;
  display: flex;
  justify-content: center;
}

.screen-wrap li {
  padding-right: 20px;
  cursor: pointer;
}

.content .menu-wrap {
  width: 260px;
  padding-bottom: 30px;
  /* margin-right: 20px */
}

.content .menu-wrap > p {
  margin: 30px 0 10px 30px;
  font-size: 16px;
}

.content .menu-wrap > p img {
  vertical-align: middle;
  width: 24px;
}

.content .menu-wrap > #movieLsit > .item > p {
  padding: 10px 0;
  padding-left: 50px;
  cursor: pointer;
}

.content .menu-wrap > div > p img {
  vertical-align: middle;
  margin-right: 4px;
}

.content .menu-wrap li {
  color: #9193bf;
  cursor: pointer;
  border-left: 3px solid #162579;
}

.content .menu-wrap li span {
  display: inline-block;
  padding: 14px 0 14px 70px;
  font-size: 15px;
}

.li_active {
  background: rgb(12, 27, 109);
  color: #38e9ec !important;
  border-left: 3px solid #38e9ec !important;
}

.vedio-wrap {
  width: calc(100% - 300px);
}

.vedio-wrap .box {
  justify-content: flex-start;
  flex-flow: row wrap;
  height: 100%;
}

.video-list {
  width: calc(100% - 220px);
  padding: 10px;
}

.video-control {
  width: 100%;
  height: calc(100% - 100px);
  position: relative;
}

.video-control img {
  cursor: pointer;
  padding: 1px;
}

.control-c {
  position: absolute;
  top: 250px;
  right: 20px;
  left: 20px;
  color: white;
  margin: 0 auto;
}

.control-c img {
  width: 22px;
  padding: 5px 5px 4px 5px;
  background: rgba(18, 20, 101, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.06);
  border-radius: 2px;
}

.control-c img:hover {
  box-shadow: 0px 1px 13px 0px rgba(70, 90, 201, 0.48);
}

.control-c img:active {
  background: rgb(44, 46, 156);
}

.control-c .box {
  margin-bottom: 18px;
}

.control-c .box > div {
  flex: 1;
}

.control-c .text-right {
  text-align: right;
}

.control-c .text-center {
  text-align: center;
}

.control-d {
  position: absolute;
  top: 40px;
  right: 10px;
  left: 10px;
  background: #121465;
  height: 180px;
  width: 180px;
  border-radius: 100%;
  z-index: 2;
  margin: 0 auto;
}

.control-d img {
  padding: 2px;
}

.control-d img:hover {
  box-shadow: 0px 1px 13px 0px rgba(70, 90, 201, 0.48);
}

.control-d img:active {
  background: rgb(44, 46, 156);
}

.control-d > div {
  height: 100%;
  width: 100%;
  position: relative;
}

.contrl_back {
  position: absolute;
  top: 42px;
  left: 42px;
  right: 42px;
  bottom: 42px;
  opacity: 0.2;
  z-index: 1;
}

.contrl_back img {
  width: 100%;
}

.control-d .up {
  position: absolute;
  top: 10px;
  left: 80px;
  z-index: 4;
}

.control-d .down {
  position: absolute;
  bottom: 10px;
  left: 80px;
  z-index: 4;
}

.control-d .left {
  position: absolute;
  top: 81px;
  left: 10px;
  z-index: 4;
}

.control-d .right {
  position: absolute;
  top: 81px;
  right: 10px;
  z-index: 4;
}

.control-p {
  position: absolute;
  top: 420px;
  right: 10px;
  left: 10px;
  z-index: 2;
  margin: 0 auto;
}

.control-p .box > div {
  flex: 1;
}

.control-p .box > div li {
  text-align: center;
  width: 80%;
  margin-left: 10%;
  cursor: pointer;
  padding: 8px 0;
  background: rgba(18, 20, 101, 0.5);
  margin-bottom: 20px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 4px;
}

.control-p .box > div li:hover {
  box-shadow: 0px 1px 13px 0px rgba(70, 90, 201, 0.48);
}

.control-p .box > div li:hover p {
  color: #ffffff;
}

.control-p .box > div li:active {
  background: rgb(44, 46, 156);
}

.control-p .box p {
  color: #a8a8e2;
}

.stream-type li {
  font-size: 12px;
  color: #a8a8e2;
}

.control_active span {
  color: #00fff9;
}

.content .menu-wrap .itemWord img {
  vertical-align: middle;
  margin-right: 4px;
}

.content .menu-wrap li {
  color: #9193bf;
  cursor: pointer;
  border-left: 3px solid #162579;
}

.content .menu-wrap li span {
  display: inline-block;
  padding: 14px 0 14px 70px;
}

.itemWord {
  position: relative;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1);
}

.itemWord:hover ul {
  display: block;
  box-shadow: 0px 1px 13px 0px rgba(0, 0, 0, 0.28);
}

.chooseItem {
  display: inline-block;
  margin-left: 10px;
  padding: 12px;
  color: #ffffff;
  font-size: 16px;
}

.itemWord ul {
  position: absolute;
  left: 0;
  top: 45px;
  background-color: #1e2f89;
  width: 100%;
  display: none;
}

.itemWord ul li {
  height: 40px;
  line-height: 40px;
  padding: 0 18px;
  font-size: 15px;
  color: #efefef;
}

.itemWord ul li:hover {
  color: #00fff9;
}

.item {
  color: #9193bf;
}
.box {
  display: flex;
  align-items: center;
  li {
    list-style-type: none;
  }
}
.listBar{
  height: calc(100% - 100px);
  margin-top: 20px;

}
</style>
