<script>
import VueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "DeviceAlarmRecord",
  components: {
    VueSeamlessScroll,
  },
  data() {
    return {
      listData: [],
    };
  },
  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: this.listData.length, // 开始无缝滚动的数据量
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 32, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created(){
      this.getElectricityStatistical()
  },
  methods: {
    getElectricityStatistical() {
      this.$api.getInOutAccount().then((res) => {
        if (res.code == 200) {
          console.log(res.data)
          this.listData=res.data
        }
      });
    },
    toDetail(item){
      var dates = new Date(item.inOutTime);
      this.$router.push("/pcms/labour/attendance/dpList?name="+item.personnelName+'&date='+dates.getTime()/1000);
    },
  },
};
</script>

<template>
  <div class="bigscreen-chart-container">
    <div class="list-data-header">
      <label class="column serialno"> 单位名称 </label>
      <label class="column"> 人员姓名 </label>
      <label class="column"> 出入时间 </label>
      <label class="column"> 出入类型 </label>
    </div>
    
    <vue-seamless-scroll
      :data="listData"
      class="list-data"
      style="margin: 0; padding: 0"
      :class-option="classOption"
    >
      <ul style="margin: 0; padding: 0;cursor:pointer">
        <li class="data-item" style="cursor:pointer" v-for="item in listData" :key="item.key" @click="toDetail(item)">
          <label class="column serialno" style="cursor:pointer">
            {{ item.companyName }}
          </label>
          <label class="column" style="cursor:pointer">
            {{ item.personnelName }}
          </label>
          <label class="column" style="cursor:pointer">
            {{ item.inOutTime }}
          </label>
          <label class="column" style="cursor:pointer">
            {{ item.inOutType==1?'进入':'离开' }}
          </label>
        </li>
      </ul>
    </vue-seamless-scroll>
  </div>
</template>

<style lang="scss" scoped>
.list-data-header {
  display: flex;
  align-items: center;
  height: 16px;
  font-size: 12px;
  color: #b8e9fc;
  font-weight: bold;
  margin: 8px 0;
  .column {
    flex: 1;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 4px;
    &.serialno {
      width: 60px;
      flex: none;
    }
  }
}
.list-data {
  width: 100%;
  height: calc(100% - 32px);
  overflow: hidden;
  color: #B8E9FC;
  .data-item {
    font-size: 12px;
    height: 24px;
    display: flex;
    align-items: center;
    background: linear-gradient(
      180deg,
      rgba(50, 88, 126, 0.16) 0%,
      #133558 100%
    );
    border-radius: 2px;
    // background: rgba(50, 91, 166, 0.5);
    // border: 1px solid;
    // border-image: linear-gradient(
    // 		270deg,
    // 		rgba(46, 132, 219, 0) 3%,
    // 		rgba(46, 132, 219, 0.78) 62%,
    // 		rgba(46, 132, 219, 0.87)
    // 	)
    // 	1 1;
    margin-bottom: 8px;
    .column {
      flex: 1;
      width: 0;
      display: inline-block;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 4px;
      &.serialno {
        width: 60px;
        flex: none;
      }
    }
  }
}
</style>
