<script>
// import ChartBar from "@/components/ChartBarRoll";
// import ChartBar from "@/components/ChartBar";

import ChartBar from "@/components/ChartBarList";

export default {
  name: "KeyDeviceMonitor",
  components: {
    ChartBar,
  },
  data() {
    return {
      eqi_totalNum: "5",
      barData: [],
      navData: {},
    };
  },
  created() {
    this.getGroupStatistical();
  },
  methods: {
    clickTo(type){
      this.$router.push("/mdms/dpDevice?deviceState="+type);
    },
    // 重点设备监控
    getGroupStatistical() {
      this.$api.getDeviceGroupStatistical().then((res) => {
        if (res.code == "200") {
          let list = res.data.list;
          console.log(res.data);
          this.navData = res.data;

          let objArr = [
            {
              typeId: "6",
              name: "视频监控",
            },

            {
              typeId: "3",
              name: "环境监测",
            },

            {
              typeId: "8",
              name: "用电监测",
            },
            {
              typeId: "1",
              name: "人行道闸",
            },
            {
              typeId: "5",
              name: "广播音柱",
            },
          ];
          // 总数
          let arrTotal = [];
          // 在线
          let onlineNumber = {
            seriesName: "在线",
            data: [],
          };
          // 故障
          let faultNumber = {
            seriesName: "故障",
            data: [],
          };
          // 离线
          let offlineNumber = {
            seriesName: "离线",
            data: [],
          };
          list.forEach((item) => {
            objArr.forEach((v, index) => {
              if (item.typeId == v.typeId) {
                onlineNumber.data[index] = item.onlineNumber;
                faultNumber.data[index] = item.faultNumber;
                offlineNumber.data[index] = item.offlineNumber;
              } else {
                onlineNumber.data.push(0);
                faultNumber.data.push(0);
                offlineNumber.data.push(0);
              }
            });
          });
          arrTotal.push(onlineNumber, faultNumber, offlineNumber);
          this.barData = arrTotal;
          //   let colorList = ["#0092ff", "#FF564F", "#C9C9C9"];
          //   this.chart02("chart002", objArr, arrTotal, colorList);
          this.eqi_totalNum = res.data.totalNumber;
          //   console.log('barData', this.barData)

          console.log(this.barData)
        }
      });
    },
    btnClick(data) {
      console.log(data);
    },
  },
};
</script>

<template>
  <div class="flex-center-container">
    <div style="width: 20%; margin-right: 2%; height: 100%">
      <div class="extra-info" @click="clickTo('')">
        <div class="name">设备总数</div>
        <div class="count">{{ navData.totalNumber || 0 }}</div>
      </div>
      <div class="extra-info" @click="clickTo(1)">
        <div class="name">在线总数</div>
        <div class="count">{{ navData.onlineTotalNumber || 0 }}</div>
      </div>
      <div class="extra-info" @click="clickTo(0)">
        <div class="name">离线总数</div>
        <div class="count">{{ navData.offlineTotalNumber || 0 }}</div>
      </div>
    </div>
    <div style="width: 78%; height: 100%">
      <ChartBar
        @btnClick="btnClick"
        :direction="'horizontal'"
        :legendColor="['#07CAEC', '#C98C56', '#FF4241']"
        :nameWidth="60"
        :nameData="['视频监控', '环境监测', '用电监测', '人行道闸', '广播音柱']"
        :barColor="[
          {
            topColor: '#fff',
            leftColor: ['#285F8A', '#06D3F0'],
            rightColor: ['#285F8A', '#06D3F0'],
          },
          {
            topColor: '#C98C56',
            leftColor: ['#FFE8C3', '#C98C56'],
            rightColor: ['#FFE8C3', '#C98C56'],
          },
          {
            topColor: '#FF4241',
            leftColor: ['#FF6766', '#FF4241'],
            rightColor: ['#FF6766', '#FF4241'],
          },
        ]"
        :barData="barData"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.flex-center-container {
  position: relative;
  .extra-info {
    height: 30%;cursor: pointer;
    margin: 0 auto;
    .name {
      font-size: 12px;
      color: #b8e9fc;
    }
    .count {
      font-size: 20px;
      font-family: acens;
      color: #ffffff;
      line-height: 24px;
      text-shadow: 0px 2px 4px rgba(178, 232, 255, 0.58);
    }
    .name {
      font-size: 14px;
    }
  }
}
</style>
