<script>
import DeviceAlarmRecord from "./DeviceAlarmRecord2"; //进场趋势

export default {
  name: "ImageProgress",
  components: { DeviceAlarmRecord },
  props: {
    duration: {
      type: Number,
      default: 0,
    },
    currentTime: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 0,
        roundLengths: true,
        setWrapperSize: true,
        grabCursor: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      datasNum:{},
      yujinNum:{},
      currentProgress: 0,
      mileData: [
        // {
        // 	milestoneNode: '施工许可',
        // 	date: '2019-08-15',
        // 	status: 'finish',
        // 	progress: 0
        // },
        // {
        // 	milestoneNode: '基坑支护施工',
        // 	date: '2019-09-16',
        // 	status: 'finish',
        // 	progress: 21.5
        // },
        // {
        // 	milestoneNode: '桩基施工',
        // 	date: '2019-10-13',
        // 	status: 'finish',
        // 	progress: 43
        // },
        // {
        // 	milestoneNode: '主体开工',
        // 	date: '2019-11-18',
        // 	status: 'finish',
        // 	progress: 64.5
        // },
        // {
        // 	milestoneNode: '结构±0',
        // 	date: '2019-12-19',
        // 	status: 'finish',
        // 	progress: 86
        // },
        // {
        // 	milestoneNode: '主体结构达到预售',
        // 	date: '2020-01-20',
        // 	status: 'finish',
        // 	progress: 107.5
        // },
        // {
        // 	milestoneNode: '样板房及展示区完成',
        // 	date: '2020-02-21',
        // 	status: 'finish',
        // 	progress: 129
        // },
        // {
        // 	milestoneNode: '主体结构封顶',
        // 	date: '2020-03-17',
        // 	status: 'finish',
        // 	progress: 150.5
        // },
        // {
        // 	milestoneNode: '外装落架',
        // 	date: '2020-04-23',
        // 	status: 'finish',
        // 	progress: 172
        // },
        // {
        // 	milestoneNode: '竣工备案',
        // 	date: '2020-05-24',
        // 	status: 'current',
        // 	progress: 193.5
        // }
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiperInstance;
    },
    timeChange() {
      let { duration, currentTime } = this;
      return { duration, currentTime };
    },
    picServer() {
      return this.$store.state.picServer;
    },
  },
  watch: {
    timeChange() {
      let nowTime = Math.round(this.currentTime * 10) / 10;
      if (!nowTime) {
        this.swiper.slideTo(0);
      } else if (
        [21.5, 43, 64.5, 86, 107.5, 129, 150.5, 172, 193.5].includes(nowTime)
      ) {
        this.swiper.slideTo(
          (Math.round((this.currentTime / this.duration) * 10) / 10) * 10
        );
      }
    },
  },
  created() {
    this.getMilestoneConfig();
  },
  methods: {
    getMilestoneConfig() {
      this.$api.getScreenStatistics().then((res) => {
        if (res.code == "200") {
          console.log(res.data);
          this.datasNum =res.data
        }
      });

      this.$api.getYjstatistical().then((res) => {
        if (res.code == "200") {
          console.log(res.data);
          this.yujinNum = res.data
        }
      });
    },


    
    btnPrev() {
      this.swiper.slidePrev();
      this.currentProgress = this.swiper.progress;
    },
    btnNext() {
      this.swiper.slideNext();
      this.currentProgress = this.swiper.progress;
    },
    btnMouseover() {
      this.$emit("imgOver");
    },
    btnMouseout() {
      this.$emit("imgOut");
    },
    btnClick(progress) {
      this.$emit("imgClick", progress);
    },
    clickTo(item){
        this.$router.push("/collision-alarm/dpAlarm-event?warnStatus="+item);
    },
    clickBtn(index){
      if(index==1){
        this.$router.push("/pcms/labour/attendance/dpList?inOutType="+1);
      }else if(index==2){
        this.$router.push("/mdms/dpElectricity?date="+(Date.parse(new Date())/1000));
      }else if(index==3){
        this.$router.push("/mdms/dpwater?date="+(Date.parse(new Date())/1000));
      }else if(index==4){
          this.$router.push("/collision-alarm/dpAlarm-event?warnStatus="+'');
      }
    }
  },
};
</script>

<template>
  <div class="page">
    <div class="navList" style="height: 50%">
      <div class="list" @click="clickBtn(1)">
        <div class="label">{{datasNum.inPersonnelCount||0}} <span>人</span></div>
        <div class="info">实时在场人数</div>
      </div>
      <div class="list" @click="clickBtn(2)">
        <div class="label">{{datasNum.electricityCount||0}}<span>KW </span></div>
        <div class="info">今日用电消耗</div>
      </div>
      <div class="list" @click="clickBtn(3)">
        <div class="label">{{datasNum.waterCount||0}} <span>t</span></div>
        <div class="info">今日用水消耗</div>
      </div>
      <div class="list" @click="clickBtn(4)">
        <div class="label">{{yujinNum.todayWarnCount||0}} <span>条</span></div>
        <div class="info">今日预警数</div>
      </div>
    </div>
    <div class="image-progress" style="height: 50%">
      <div class="progress-title">
        <img class="icon" src="../../assets/img/title_icon.png" />
        <label class="name"> 预警监测 </label>
      </div>
      <div class="xiabulan">
        <div class="leftBar">
          <div class="list" @click="clickTo(0)">
            <div class="info" style="font-size: 12px;">未处理预警数</div>
            <div class="xiantiao"></div>
            <div class="label" style="color:#FE6D27;font-size: 18px;">{{yujinNum.unDisposedWarnCount||0}} </div>
          </div>
          <div class="bottomList" >
            <div class="list" style="margin-right: 2%" @click="clickTo('')">
              <div class="info" style="font-size: 12px;">累计预警数</div>
              <div class="xiantiao"></div>
              <div class="label" style="color:#B2E8FF;font-size: 18px;">{{yujinNum.warnCount||0}} </div>
            </div>
            <div class="list" @click="clickTo(1)">
              <div class="info" style="font-size: 12px;">已处理预警数</div>
              <div class="xiantiao"></div>
              <div class="label" style="color:#B2E8FF;font-size: 18px;">{{yujinNum.disposedWarnCount||0}} </div>
            </div>
          </div>
        </div>
        <div class="rightBar">
          <DeviceAlarmRecord />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page {
  width: 100%;
  height: 100%;
  position: relative;
}
@keyframes fromBottomAnimation {
  0% {
    transform: translateY(134px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.image-progress {
  //   position: fixed;
  position: absolute;
  height: 160px;
  bottom: 0;
  width: 100%;
  //   left: 380px;
  //   right: 360px;
  padding-bottom: 10px;
  border-radius: 144px 144px 0 0;
  background: linear-gradient(
    360deg,
    rgba(39, 159, 254, 0.18),
    rgba(71, 147, 205, 0)
  );
  border: 2px solid rgba(39, 159, 254, 0.28);
  //   animation: fromBottomAnimation 1.5s;
  .progress-title {
    position: absolute;
    top: -19px;
    left: 67px;
    display: flex;
    align-items: center;
    width: 134px;
    height: 36px;
    background-image: url("../../assets/img/label_base_10.png");
    background-size: 134px 34px;
    background-repeat: no-repeat;
    background-position: center top;
    .name {
      margin: 2px 0 0 8px;
      line-height: 1;
      font-family: biaotihei;
      font-size: 18px;
      background: linear-gradient(180deg, #ffffff, #ffffff 22%, #77d8ff 100%);
      text-shadow: 0px 2px 4px rgba(178, 232, 255, 0.58);
      background-clip: text;
      color: transparent;
    }
  }
  .xiabulan {
    display: flex;
    width: 100%;
    height: 100%;
    .leftBar {
      width: 36%;
      padding-left: 90px;
      padding-top: 30px;
      .list {
        width: 100%;
        height: 50px;cursor: pointer;
        background: linear-gradient(
          90deg,
          rgba(205, 121, 71, 0.32) 0%,
          rgba(205, 121, 71, 0.32) 100%
        );
        border-radius: 6px;
        border: 1px solid #9e804d;
        text-align: center;
        padding-top: 4px;
      }
    }
    .rightBar {
      width: 64%;
      padding: 0 120px 0 30px;
    }
    .bottomList {
      display: flex;
      margin-top: 6px;
      .list {
        width: 49%;
        height: 50px;
        background: linear-gradient(
          270deg,
          rgba(71, 147, 205, 0) 0%,
          rgba(41, 100, 145, 0.78) 49%,
          rgba(71, 147, 205, 0) 100%
        );
        border-radius: 6px;
        border: 1px solid #4d769e;
      }
    }
    .right {
      width: 64%;
    }
  }
}
.navList {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  .list {
    // width: 200px;
    width: 22%;cursor: pointer;
    height: 88px;
    background-image: url("../../assets/img/beijtuList.png");
    background-size: 100% 100%;
    border-radius: 6px;
    border: 1px solid #4d769e;
    text-align: center;
    padding-top: 10px;
    .label {
      font-size: 24px;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
      color: #ffffff;
      text-shadow: 0px 2px 4px rgba(178, 232, 255, 0.58);
      span {
        font-size: 14px;
      }
    }
    .info {
      font-size: 14px;
      font-weight: 400;
      color: #b8e9fc;
      line-height: 20px;
    }
  }
}
.xiantiao {
  // position: absolute;
  // right: -4px;
  // top: 4px;
  // content: "";
  display: inline-block;
  width: 80%;
  height: 1px;
  opacity: 0.6;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(252, 253, 253, 0.35) 18%,
    #f7f8f8 50%,
    rgba(248, 249, 249, 0.85) 58%,
    rgba(251, 252, 252, 0.49) 77%,
    rgba(255, 255, 255, 0)
  );
}
</style>
